import React, { useState, useRef, useEffect } from "react";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import styles from "./FrontPageEvent.module.scss";

import {
  createDescriptionContent,
  createDateString
} from './utils'

const FrontPageEvent = ({
  eventDate,
  endDate,
  startTime,
  endTime,
  dateString,
  title,
  description,
  richTextDescription
}) => {

  const descriptionContainer = useRef()
  const descriptionHtml = useRef()
  const chevronIcon = useRef()

  const [descriptionIsOpen, setDescriptionIsOpen] = useState(false)
  const [chevronVisible, setChevronVisible] = useState(true)

  const weekDay = dateString.slice(0, 2)
  const date = createDateString(eventDate)
  const descriptionContent = createDescriptionContent(description, richTextDescription)

  useEffect(() => {
    if (descriptionHtml.current.clientHeight <= 46 && chevronVisible) {
      setChevronVisible(false)
      descriptionContainer.current.style.height = 'auto'
    }
  }, [])

  const openDescription = () => {
    if (!chevronVisible) {
      return
    }

    if (!descriptionIsOpen) {
      descriptionContainer.current.style.height = descriptionHtml.current.clientHeight + 'px'
      chevronIcon.current.style.transform = 'rotate(180deg)'
      return setDescriptionIsOpen(true)
    }
    descriptionContainer.current.style.height = 46 + 'px'
    chevronIcon.current.style.transform = 'rotate(0deg)'
    return setDescriptionIsOpen(false)
  }

  return (
    <>
      <div className={styles.eventWrapper} onClick={() => { openDescription() }}>
        <div className={styles.timeContainer}>
          <p className={styles.eventDate}>{weekDay} {date}</p>
          <p className={styles.eventTime}>{startTime} - {endTime}</p>
        </div>

        <div>
          <p className={styles.eventTitle}>{title}</p>
          <div ref={descriptionContainer} className={styles.descriptionContainer}>
            <div className={styles.descriptionHtml} ref={descriptionHtml}>
              <div>
                {descriptionContent}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.chevronContainer}>
          {chevronVisible && (
            <div className={styles.chevronIcon} ref={chevronIcon}>
              <ChevronDownIcon />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FrontPageEvent;
