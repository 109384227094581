import React, { useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import styles from "./Login.module.scss";
import Terms from "./Terms";
import LoginForm from "../LoginForm/LoginForm";
import RegisterForm from "../RegisterForm/RegisterForm";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import hetaLogo from '../../assets/heta-round.svg'

const Login = ({ updateToken }) => {
  const [view, setView] = useState("login");
  const [terms, setTerms] = useState(false);

  return (
    <div className={styles.screenContainer}>
      <TransitionGroup>
        <CSSTransition key={view} timeout={300} classNames="login-animation">
          <div className={styles.loginContainer}>
            <div className={styles.titleContainer}>
              <div className={styles.title}>
                <img src={hetaLogo} alt="Heta logo" />
                <h1>Heta</h1>
              </div>
            </div>
            {view === "login" && (
              <LoginForm setView={setView} updateToken={updateToken} />
            )}
            {view === "register" && (
              <RegisterForm setView={setView} setTerms={setTerms} />
            )}
            {view === "forgot-password" && <ForgotPassword setView={setView} />}
            {view === "resend-confirmation" && (
              <ForgotPassword setView={setView} resend={true} />
            )}
          </div>
        </CSSTransition>
      </TransitionGroup>
      {terms && <Terms setTerms={setTerms} />}
    </div>
  );
};

export default Login;
