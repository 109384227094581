import React, { useContext, useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button, InputError, TextInput } from "../Inputs/Inputs";
import SecurityContext from "../Context/SecurityContext";
import styles from "../Login/Login.module.scss";

export default function LoginForm(props) {
  const { setView, updateToken } = props;
  const { api } = useContext(SecurityContext);

  const [error, setError] = useState("");

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Sähköpostiosoite on virheellinen")
      .required("Pakollinen tieto"),
    password: Yup.string().required("Pakollinen tieto"),
  });

  function handleLogin(values, { setSubmitting }) {
    setSubmitting(true);
    setError("");

    if (values.email && values.password) {
      api
        .loginUser(values.email, values.password)
        .then(({ headers, data }) => {
          setSubmitting(false);
          if (
            headers &&
            data &&
            data.data &&
            headers["access-token"] &&
            headers["client"] &&
            data.data.uid
          ) {
            updateToken({
              accessToken: headers["access-token"],
              tokenType: headers["token-type"],
              client: headers["client"],
              uid: data.data.uid,
            });
          }
        })
        .catch(err => {
          console.log(err);
          setSubmitting(false);
          setError("Incorrect username and/or password.");
        });
    } else {
      setSubmitting(false);
    }
  }

  return (
    <>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={LoginSchema}
        onSubmit={handleLogin}
      >
        {({ values, isSubmitting }) => (
          <Form className={styles.form}>
            <TextInput
              formik
              required
              type="email"
              name="email"
              label="Sähköposti"
            />
            <ErrorMessage name="email" component={InputError} />
            <TextInput
              formik
              required
              type="password"
              name="password"
              label="Salasana"
            />
            <ErrorMessage name="password" component={InputError} />
            {error && (
              <p className={styles.errMsg}>
                <strong>Virhe:</strong> {error}
              </p>
            )}
            <div className={styles.submit}>
              <Button
                blueButton
                type="submit"
                disabled={isSubmitting || !values.email || !values.password}
              >
                Kirjaudu sisään
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <div
        className={styles.goToView}
        onClick={() => setView("forgot-password")}
      >
        <span>Unohditko salasanasi?</span>
      </div>
      <div className={styles.switchViewLabel}>
        <span>Eikö ole vielä tiliä?</span>
      </div>
      <Button
        grey
        className={styles.switchView}
        onClick={() => setView("register")}
      >
        Luo tili
      </Button>
    </>
  );
}
