import React from "react";
import { FiStar } from "react-icons/fi";

import styles from "./AnnouncementTags.module.scss";

const AnnouncementTags = ({
  handleCheck,
  filterTags,
}) => {
  return (
    <div className={styles.filterItemsContainer}>
      <h5>Näytä tiedotteita...</h5>
      <div className={styles.checkBoxContainer}>
        <div>
          <input
            type="checkbox"
            checked={filterTags.userComplex}
            onChange={() => { handleCheck('userComplex') }}
            name="omaTalo"
          />
          <label>Oma Talo</label>
        </div>
        <div>
          <input
            type="checkbox"
            checked={filterTags.Heka}
            onChange={() => { handleCheck('Heka') }}
            name="Heka"
          />
          <label>Heka</label>
        </div>
        <div>
          <input
            type="checkbox"
            checked={filterTags.Ekoekspertti}
            onChange={() => { handleCheck('Ekoekspertti') }}
            name="Ekoekspertti"
          />
          <label>Ekoekspertti</label>
        </div>
        <div>
          <input
            type="checkbox"
            checked={filterTags.important}
            onChange={() => { handleCheck('important') }}
            name="important"
          />
          <label>
            {/* Vain */}
            <span className={styles.importantContainer}>
              <FiStar /> tärkeät
            </span>
            {/* <span>tiedotteet</span> */}
          </label>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementTags;
