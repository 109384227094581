import { createSlice } from '@reduxjs/toolkit'

export const currentComplexSlice = createSlice({
  name: 'currentComplex',
  initialState: {
    id: '',
    identifier: '',
    name: '',
    addresses: []
  },

  reducers: {
    setCurrentComplex: (state, action) => {
        state.id = action.payload.id
        state.identifier = action.payload.identifier
        state.name = action.payload.name
        state.addresses = action.payload.addresses
    }
  },
})

export const { setCurrentComplex } = currentComplexSlice.actions

export default currentComplexSlice.reducer