import React from "react";
import { Link } from "react-router-dom";

import styles from "./TopNavBar.module.scss";

const TopNavBar = ({ items }) => {
  return (
    <div className={styles.itemsContainer}>
      {items?.slice(1).map((item, index) => {
        return (
          <Link key={index} to={item.to}>
            <div className={styles.topNav}>
              <div className={styles.contentWrapper}>
                <div className={styles.iconContainer}>{item.icon}</div>
                <div className={styles.itemLabel}>{item.toptitle}</div>
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default TopNavBar;
