import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import PageContainer from "../../components/PageContainer/PageContainer";
import { BiArrowBack } from "react-icons/bi";
import SecurityContext from "../../components/Context/SecurityContext";
import EventsContainer from "./EventsContainer/EventsContainer";

import styles from "./Events.module.scss";

const Events = () => {
    const { api } = useContext(SecurityContext);
    let history = useHistory();

    const [events, setEvents] = useState({
        singleEvents: [],
        recurringEvents: []
    })
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        api.getAllBuildingComplexEvents()
            .then(res => {
                setEvents({
                    singleEvents: res.data.other_events,
                    recurringEvents: res.data.recurring_events
                })
                setLoading(false)
            })
    }, [])

    return (
        <PageContainer>
            <div className={styles.pageHeader}>
                <button>
                    <BiArrowBack
                        style={{ fontSize: "1.5rem", cursor: "pointer" }}
                        onClick={() => history.goBack()}
                    />
                </button>
            </div>
            {!loading && (
                <div className={styles.pageWrapper}>
                    <EventsContainer
                        events={events.recurringEvents}
                        type="recurring"
                    />

                    <EventsContainer
                        events={events.singleEvents}
                        type="single"
                    />
                </div>
            )}

            {loading && (
                <div className={styles.pageWrapper}>
                    <p>Ladataan...</p>
                </div>
            )}

        </PageContainer>
    );
};

export default Events;
