import React from "react";
import styles from "./ChallengeInfo.module.scss";

const ChallengeInfo = ({ tags, texts }) => {
  const mappedTags =
    tags && tags.length
      ? tags.map((tag, index) => (
          <div key={index} className={styles.item}>
            {tag}
            {(index + 1 < tags.length || (texts && texts.length)) ? (
              <div className={styles.divider}></div>
            ) : null}
          </div>
        ))
      : null;

  const mappedTexts =
    texts && texts.length
      ? texts.map((text, index) => (
          <div key={index} className={styles.item}>
            <span className={styles.text}>{text}</span>
            {index + 1 < texts.length && <div className={styles.divider}></div>}
          </div>
        ))
      : null;

  return (
    <div className={styles.info}>
      {mappedTags}
      {mappedTexts}
    </div>
  );
};

export default ChallengeInfo;
