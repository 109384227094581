export function RichText({ content }) {
  const parsedContent = processContent(content);
  return (
    <p
      dangerouslySetInnerHTML={{ __html: parsedContent }}
    />
  );
}

function processContent(content) {
  // Make links open in a new tab
  let result = content?.replace(
    "<a ",
    "<a target='_blank' rel='noopener noreferrer' "
  );

  return result;
}
