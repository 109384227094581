import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { BiArrowBack } from "react-icons/bi";
import PageContainer from "../components/PageContainer/PageContainer";
import Container from "../components/Container/Container";
import { Button } from "../components/Inputs/Inputs";
import SecurityContext from "../components/Context/SecurityContext";
import PageAlignment from "../components/common/PageAlignment/PageAlignment";
import DeleteAccountModal from "../components/DeleteAccountModal/DeleteAccountModal";

const Settings = ({ resetToken }) => {

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const { api } = useContext(SecurityContext);
  let history = useHistory();

  function editPassword() {
    history.push("/settings/change-password");
  }

  function editComplex() {
    history.push("/settings/change-complex");
  }

  function deleteAccountModal(isOpen) {
    setDeleteModalOpen(isOpen)
  }

  function logout() {
    api
      .logoutUser()
      .then(data => {
        console.log(data && data.success && "Signed out");
        resetToken();
      })
      .catch(err => {
        console.log(err);
        resetToken();
      });
  }

  return (
    <PageContainer>
      <BiArrowBack
        style={{ fontSize: "1.5rem", cursor: "pointer", margin: "2rem 0 0 2rem" }}
        onClick={() => history.goBack()}
      />
      <PageAlignment>
        <Container>
          <Button blueButton onClick={() => logout()}>
            Kirjaudu ulos
          </Button>
        </Container>
        <Container>
          <Button grey onClick={() => editPassword()}>
            Vaihda salasana
          </Button>
        </Container>
        <Container>
          <Button redButton onClick={() => deleteAccountModal(true)}>
            Poista tili
          </Button>
        </Container>
        {/* <Container>
          <Button grey onClick={() => editComplex()}>
            Vaihda asuinkohdetta
          </Button>
        </Container> */}

        <DeleteAccountModal
          isOpen={deleteModalOpen}
          setDeleteModalOpen={setDeleteModalOpen}
          resetToken={resetToken}
        />
      </PageAlignment>
    </PageContainer>
  );
};

export default Settings;
