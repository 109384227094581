import React from "react";
import classNames from "classnames";
import styles from "./Inputs.module.scss";
import CheckIcon from "mdi-react/CheckIcon";
import { Field } from "formik";

export const Button = ({
  onClick,
  type,
  disabled,
  outline,
  grey,
  children,
  tealButton,
  blueButton,
  redButton,
  whiteButton,
  darkGreenButton,
}) => {
  const buttonClassNames = classNames(styles.button, {
    [styles.outline]: outline,
    [styles.grey]: grey,
    [styles.tealButton]: tealButton,
    [styles.blueButton]: blueButton,
    [styles.redButton]: redButton,
    [styles.redButton]: redButton,
    [styles.whiteButton]: whiteButton,
    [styles.darkGreenButton]: darkGreenButton,
  });

  return (
    <button
      className={buttonClassNames}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export const Radio = ({ formik, id, name, label, value, greenBackground }) => {
  const radioClassNames = classNames(styles.radio, {
    [styles.greenBackground]: greenBackground,
  });

  return (
    <div className={styles.inputContainer}>
      {formik ? (
        <Field
          type="radio"
          id={id}
          name={name}
          value={value}
          className={styles.hiddenInput}
        />
      ) : (
        <input
          type="radio"
          id={id}
          name={name}
          value={value}
          className={styles.hiddenInput}
        />
      )}
      <label htmlFor={id} className={radioClassNames}>
        <span>{label}</span>
      </label>
    </div>
  );
};

export const CustomRadio = ({
  formik,
  id,
  name,
  label,
  value,
  pollPercent,
  disabled,
}) => {
  return (
    <>
      <label className={styles.customRadio}>
        {formik ? (
          <Field
            type="radio"
            id={id}
            name={name}
            value={value}
            className={styles.hiddenInput}
            disabled={disabled}
          />
        ) : (
          <input
            type="radio"
            id={id}
            name={name}
            value={value}
            className={styles.hiddenInput}
            disabled={disabled}
          />
        )}
        <div className={styles.customLabel}>
          <div className={styles.filledLabel}>{label}</div>
          <div>{pollPercent}</div>
        </div>
      </label>
    </>
  );
};

export const Checkbox = ({
  formik,
  required,
  id,
  name,
  label,
  value,
  greenBackground,
}) => {
  const checkboxClassNames = classNames(styles.checkbox, {
    [styles.greenBackground]: greenBackground,
  });
  
  return (
    <div className={styles.inputContainer}>
      {formik ? (
        <Field
          required={!!required}
          type="checkbox"
          id={id}
          name={name}
          value={value}
          className={styles.hiddenInput}
        />
      ) : (
        <input
          required={!!required}
          type="checkbox"
          id={id}
          name={name}
          value={value}
          className={styles.hiddenInput}
        />
      )}
      <label htmlFor={id} className={checkboxClassNames}>
        <div className={styles.icon}>
          <CheckIcon />
        </div>
        <span>{label}</span>
      </label>
    </div>
  );
};

export const TextInput = ({
  formik,
  required,
  type,
  name,
  label,
  placeholder,
}) => {
  return (
    <div className={styles.textInputContainer}>
      {formik ? (
        <Field
          type={type ? type : "text"}
          id={name}
          name={name}
          className={`${styles.textInput} ${
            placeholder ? styles.showPlaceholder : ""
          }`}
          placeholder={placeholder || label}
          required={!!required}
        />
      ) : (
        <input
          type={type ? type : "text"}
          id={name}
          name={name}
          className={`${styles.textInput} ${
            placeholder ? styles.showPlaceholder : ""
          }`}
          placeholder={placeholder || label}
          required={!!required}
        />
      )}
      {label && <label className={styles.textInputLabel}>{label}</label>}
    </div>
  );
};

export const TextInputBox = ({
  formik,
  required,
  type,
  name,
  label,
  placeholder,
  readOnly,
}) => {
  return (
    <div className={styles.textInputBoxContainer}>
      {formik ? (
        <Field
          type={type ? type : "text"}
          id={name}
          name={name}
          className={`${styles.textInputBox} ${
            placeholder ? styles.showPlaceholder : ""
          }`}
          placeholder={placeholder || label}
          required={!!required}
          readOnly={readOnly}
        />
      ) : (
        <input
          type={type ? type : "text"}
          id={name}
          name={name}
          className={`${styles.textInputBox} ${
            placeholder ? styles.showPlaceholder : ""
          }`}
          placeholder={placeholder || label}
          required={!!required}
          readOnly={readOnly}
        />
      )}
      {label && <label className={styles.textInputBoxLabel}>{label}</label>}
    </div>
  );
};

export const DropdownSelect = ({
  formik,
  required,
  type,
  name,
  label,
  placeholder,
}) => {
  return (
    <div className={styles.textInputBoxContainer}>
      {formik ? (
        <Field name={name} as="select" required={!!required}>
          <option value="">{label}</option>
          <option value="red">Red</option>
          <option value="green">Green</option>
          <option value="blue">Blue</option>
        </Field>
      ) : (
        <Field name={name} as="select" required={!!required}>
          <option value="">{label}</option>
          <option value="red">Red</option>
          <option value="green">Green</option>
          <option value="blue">Blue</option>
        </Field>
      )}
    </div>
  );
};

export const NumberInput = ({ formik, required, name, label, min, max }) => {
  return (
    <div className={styles.textInputContainer}>
      {formik ? (
        <Field
          type="number"
          id={name}
          name={name}
          className={styles.textInput}
          min={min}
          max={max}
          required={!!required}
        />
      ) : (
        <input
          type="number"
          id={name}
          name={name}
          className={styles.textInput}
          min={min}
          max={max}
          required={!!required}
        />
      )}
      <label className={styles.textInputLabel}>{label}</label>
    </div>
  );
};

export const DateInput = ({ formik, required, name, label, min, max }) => {
  return (
    <div className={styles.textInputContainer}>
      {formik ? (
        <Field
          type="date"
          id={name}
          name={name}
          className={styles.textInput}
          required={!!required}
        />
      ) : (
        <input
          type="date"
          id={name}
          name={name}
          className={styles.textInput}
          required={!!required}
        />
      )}
      <label className={styles.textInputLabel}>{label}</label>
    </div>
  );
};

export const TextArea = ({ formik, name, label, placeholder, required }) => {
  return (
    <div className={styles.textAreaContainer}>
      {formik ? (
        <Field
          as="textarea"
          id={name}
          name={name}
          className={`${styles.textArea} ${
            placeholder ? styles.showPlaceholder : ""
          }`}
          placeholder={placeholder || label}
          required={!!required}
        />
      ) : (
        <textarea
          id={name}
          name={name}
          className={`${styles.textArea} ${
            placeholder ? styles.showPlaceholder : ""
          }`}
          placeholder={placeholder || label}
          required={!!required}
        />
      )}
      {label && <label className={styles.textAreaLabel}>{label}</label>}
    </div>
  );
};
export const TextAreaBox = ({ formik, name, label, placeholder, required }) => {
  return (
    <div className={styles.textBoxContainer}>
      {formik ? (
        <Field
          as="textarea"
          id={name}
          name={name}
          className={`${styles.textAreaBox} ${
            placeholder ? styles.showPlaceholder : ""
          }`}
          placeholder={placeholder || label}
          required={!!required}
        />
      ) : (
        <textarea
          id={name}
          name={name}
          className={`${styles.textAreaBox} ${
            placeholder ? styles.showPlaceholder : ""
          }`}
          placeholder={placeholder || label}
          required={!!required}
        />
      )}
      {label && <label className={styles.textAreaLabel}>{label}</label>}
    </div>
  );
};

export const InputError = ({ children }) => {
  return (
    <div className={styles.errorMessage}>
      <span>{children}</span>
    </div>
  );
};
