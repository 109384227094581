export function createAsukasToimikuntaPosts(message, contact, yearClock, tasks) {

    let asukasToimikunta = []

    if (tasks) {
        asukasToimikunta.push({
            "id": "t1",
            "title": "TEHTÄVÄT",
            "content": tasks
        })
    }

    if (yearClock) {
        asukasToimikunta.push({
            "id": "t2",
            "title": "VUOSIKELLO",
            "content": yearClock
        })
    }

    if (message) {
        asukasToimikunta.push({
            "id": "t3",
            "title": "ESITTELY",
            "content": message
        })
    }

    if (contact) {
        asukasToimikunta.push({
            "id": "t4",
            "title": "YHTEYSTIEDOT",
            "content": contact
        })
    }

    return asukasToimikunta
}