import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import classNames from "classnames";
import styles from "./HistorySelector.module.scss";

import SectionHeading from "../SectionHeading/SectionHeading";

import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";

const HistorySelector = ({
  history,
  setHistory,
  historyItems,
  season,
  error,
  context,
  width,
}) => {
  const historySelectorClassNames = classNames(styles.historySelector, {
    [styles.narrow]: width === "narrow",
  });
  let showBack = false;
  let showForward = false;
  if (season) {
    showBack = !error && history > -4;
    showForward = !error && history < 0;
  } else {
    showBack = !error && history < historyItems.length - 1;
    showForward = !error && history > 0;
  }

  function backHistory() {
    if (season) {
      setHistory(history - 1);
    } else {
      setHistory(history + 1);
    }
  }

  function forwardHistory() {
    if (season) {
      setHistory(history + 1);
    } else {
      setHistory(history - 1);
    }
  }

  return (
    <div className={historySelectorClassNames}>
      <div className={styles.buttonContainer}>
        {showBack && (
          <button className={styles.button} onClick={() => backHistory()}>
            <ChevronLeftIcon />
          </button>
        )}
      </div>
      <div className={styles.container}>
        <h2 className={styles.context}>{context}</h2>
        <div className={styles.selection}>
          <TransitionGroup>
            <CSSTransition
              key={history}
              timeout={300}
              classNames="header-animation"
            >
              <div className={styles.label}>
                <SectionHeading>
                  {season ? season.period : historyItems[history]}
                </SectionHeading>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        {showForward && (
          <button className={styles.button} onClick={() => forwardHistory()}>
            <ChevronRightIcon />
          </button>
        )}
      </div>
    </div>
  );
};

export default HistorySelector;
