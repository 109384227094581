import React from "react";
import { Switch, withRouter } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const AnimatedSwitch = withRouter(
  ({ location, children, keySlice, animation, timeout }) => {
    const key = location.pathname.slice(keySlice[0], keySlice[1]);
    return (
      <TransitionGroup>
        <CSSTransition key={key} classNames={animation} timeout={timeout}>
          <Switch location={location}>{children}</Switch>
        </CSSTransition>
      </TransitionGroup>
    );
  }
);

export default AnimatedSwitch;
