import React from "react";
import { Button } from "../Inputs/Inputs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Modal from "../common/Modal/Modal";
import styles from "../common/Modal/Modal.module.scss";

const ProposalModal = ({
  confirmationModal,
  setConfirmationModal,
  handleSubmitProposal,
}) => {
  if (!confirmationModal) return null;
  return (
    <Modal>
      <div className={styles.proposalModalWrapper}>
        <p>
          <AiOutlineCloseCircle
            onClick={() => setConfirmationModal(false)}
            className={styles.closeButton}
          />
        </p>

        <p>Oletko varma, että haluat lähettää?</p>
        <div className={styles.proposalmodalButton}>
          <Button onClick={handleSubmitProposal} type="submit" tealButton>
            Kyllä, lähetä
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ProposalModal;
