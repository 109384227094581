import React, { useState, useRef, useEffect } from "react"
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import Post from "./Post/Post";
import { Collapse } from "react-collapse";

import {
    titleOrPostsContainsSearchQuery
} from './utils'

import styles from "./DropDownBox.module.scss";

export default function DropDownBox({
    title,
    posts,
    searchQuery,
    useBorder,
}) {

    const chevronIcon = useRef()
    const [dropDownOpen, setDropDownOpen] = useState(false)

    const openDropDown = () => {
        if (dropDownOpen) {
            chevronIcon.current.style.transform = 'rotate(0)'
            setDropDownOpen(false)
        } else {
            chevronIcon.current.style.transform = 'rotate(180deg)'
            setDropDownOpen(true)
        }
    }

    useEffect(() => {
        const contains = titleOrPostsContainsSearchQuery(title, posts, searchQuery)

        if (contains && !dropDownOpen) {
            openDropDown()
        }

        else if (!contains && dropDownOpen) {
            openDropDown()
        }
    }, [searchQuery])

    let postsContent

    if (posts) {
        postsContent = posts.map(post => {
            return (
                <Post
                    key={post.id}
                    post={post}
                    searchQuery={searchQuery}
                />
            )
        })
    }

    return (
        <div style={{ borderBottom: useBorder ? 'solid 1px lightgray' : 'none' }}>
            <div onClick={openDropDown} className={styles.titleContainer}>
                <p className={styles.title}>{title}</p>
                <div className={styles.chevronIcon} ref={chevronIcon}>
                    <ChevronDownIcon />
                </div>
            </div>

            <Collapse isOpened={dropDownOpen}>
                <div className={styles.collapseContent}>
                    {postsContent}
                </div>
            </Collapse>
        </div>
    )
}