import React from "react";

export const Flag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28.319" height="42">
    <g
      data-name="Group 95"
      fill="none"
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    >
      <path
        data-name="Path 293"
        d="M1 22.391V3.707h0c5.507-7.375 16.1 2.99 26.319 0-2.318 2.99-7.484 7.989-13.159 7.989 8.142 7.974 13.159 10.7 13.159 10.7v-.015c-10.214 2.99-20.812-7.375-26.319 0z"
      />
      <path data-name="Line 190" d="M1 7.807v33.192" />
    </g>
  </svg>
);

export const Blink = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28.017" height="28.006">
    <g
      data-name="Group 82"
      fill="none"
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    >
      <g data-name="Group 79">
        <path data-name="Line 176" d="M14.459 20.287V7.717" />
        <path data-name="Line 177" d="M20.744 14.002H8.174" />
        <g data-name="Group 78">
          <path data-name="Line 178" d="M18.903 18.446l-8.888-8.888" />
          <path data-name="Line 179" d="M18.903 9.558l-8.888 8.888" />
        </g>
      </g>
      <path data-name="Line 180" d="M6.624 6.167L4.967 4.51" />
      <path data-name="Line 181" d="M23.482 23.026l-1.657-1.657" />
      <g data-name="Group 80">
        <path data-name="Line 182" d="M21.961 6.499l1.634-1.634" />
        <path data-name="Line 183" d="M5.336 23.125l1.634-1.634" />
      </g>
      <path data-name="Line 184" d="M14.458 3.327L14.457 1" />
      <path data-name="Line 185" d="M14.458 27.004l.005-2.327" />
      <g data-name="Group 81">
        <path data-name="Line 186" d="M24.684 14.058l2.327.016" />
        <path data-name="Line 187" d="M1.007 13.893l2.327.016" />
      </g>
    </g>
  </svg>
);

export const HandSmall = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28.001" height="27.818">
    <g
      data-name="Group 61"
      fill="none"
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    >
      <path
        data-name="Path 232"
        d="M12.728 9.34l3.253-4.746a2.185 2.185 0 10-3.6-2.47L7.438 9.449"
      />
      <path
        data-name="Path 233"
        d="M15.696 13.709h9.121a2.185 2.185 0 100-4.369H12.7"
      />
      <path
        data-name="Path 234"
        d="M17.513 18.079a2.185 2.185 0 100-4.37h-2.628a2.185 2.185 0 000 4.37z"
      />
      <path
        data-name="Path 235"
        d="M17.513 22.449a2.185 2.185 0 100-4.37h-2.628a2.185 2.185 0 000 4.37z"
      />
      <path
        data-name="Path 236"
        d="M17.513 26.818a2.185 2.185 0 100-4.37h-2.628a2.185 2.185 0 000 4.37z"
      />
      <path
        data-name="Path 237"
        d="M14.886 26.818H9.321c-5.674 0-8.433-1.087-7.075-7.752"
      />
      <path
        data-name="Path 238"
        d="M2.646 19.067A5.274 5.274 0 011 15.231c0-3.848 3.216-5.891 6.433-5.782"
      />
    </g>
  </svg>
);

export const HandLarge = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="75.127" height="74.148">
    <g
      data-name="Group 61"
      fill="none"
      stroke="#B6D4BB"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
    >
      <path
        data-name="Path 232"
        d="M34.032 24.165L43.055 11a6.061 6.061 0 10-10-6.852L19.344 24.466"
      />
      <path
        data-name="Path 233"
        d="M42.266 36.285h25.3a6.06 6.06 0 000-12.12H33.955"
      />
      <path
        data-name="Path 234"
        d="M47.306 48.406a6.06 6.06 0 100-12.121h-7.291a6.06 6.06 0 000 12.121z"
      />
      <path
        data-name="Path 235"
        d="M47.306 60.527a6.061 6.061 0 100-12.121h-7.291a6.061 6.061 0 000 12.121z"
      />
      <path
        data-name="Path 236"
        d="M47.306 72.648a6.06 6.06 0 100-12.121h-7.291a6.06 6.06 0 000 12.121z"
      />
      <path
        data-name="Path 237"
        d="M40.015 72.648H24.577c-15.74 0-23.392-3.016-19.627-21.505"
      />
      <path
        data-name="Path 238"
        d="M6.066 51.146A14.629 14.629 0 011.5 40.505c0-10.674 8.922-16.34 17.844-16.039"
      />
    </g>
  </svg>
);

export const Snow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="75.16" height="75.16">
    <g
      data-name="Group 60"
      fill="none"
      stroke="#A0C7E1"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
    >
      <path data-name="Line 121" d="M37.58 1.5v36.08" />
      <path
        data-name="Path 214"
        d="M49.898 11.435L37.58 21.995l-12.318-10.56"
      />
      <path data-name="Line 122" d="M37.58 73.66V37.58" />
      <path
        data-name="Path 215"
        d="M49.898 63.727L37.58 53.167l-12.318 10.56"
      />
      <path data-name="Line 123" d="M37.58 1.5v36.08" />
      <path data-name="Path 216" d="M25.262 11.435l12.318 10.56 12.318-10.56" />
      <path data-name="Line 124" d="M37.58 73.66V37.58" />
      <path data-name="Path 217" d="M25.262 63.727l12.318-10.56 12.318 10.56" />
      <path data-name="Line 125" d="M37.58 1.5v36.08" />
      <path
        data-name="Path 218"
        d="M49.898 11.435L37.58 21.995l-12.318-10.56"
      />
      <path data-name="Line 126" d="M37.58 73.66V37.58" />
      <path
        data-name="Path 219"
        d="M49.898 63.727L37.58 53.167l-12.318 10.56"
      />
      <path data-name="Line 127" d="M37.58 1.5v36.08" />
      <path data-name="Path 220" d="M25.262 11.435l12.318 10.56 12.318-10.56" />
      <path data-name="Line 128" d="M37.58 73.66V37.58" />
      <path data-name="Path 221" d="M25.262 63.727l12.318-10.56 12.318 10.56" />
      <path data-name="Line 129" d="M37.58 1.5v36.08" />
      <path
        data-name="Path 222"
        d="M49.898 11.435L37.58 21.995l-12.318-10.56"
      />
      <path data-name="Line 130" d="M37.58 73.66V37.58" />
      <path
        data-name="Path 223"
        d="M49.898 63.727L37.58 53.167l-12.318 10.56"
      />
      <path data-name="Line 131" d="M37.58 1.5v36.08" />
      <path data-name="Path 224" d="M25.262 11.435l12.318 10.56 12.318-10.56" />
      <path data-name="Line 132" d="M37.58 73.66V37.58" />
      <path data-name="Path 225" d="M25.262 63.727l12.318-10.56 12.318 10.56" />
      <path data-name="Line 133" d="M73.66 37.58H37.58" />
      <path data-name="Path 226" d="M63.727 25.262L53.167 37.58l10.56 12.318" />
      <path data-name="Line 134" d="M1.5 37.58h36.08" />
      <path data-name="Path 227" d="M11.435 25.262l10.56 12.318-10.56 12.318" />
      <path data-name="Line 135" d="M73.66 37.58H37.58" />
      <path data-name="Path 228" d="M63.727 49.898L53.167 37.58l10.56-12.318" />
      <path data-name="Line 136" d="M1.5 37.58h36.08" />
      <path data-name="Path 229" d="M11.435 49.898l10.56-12.318-10.56-12.318" />
      <path data-name="Line 137" d="M73.66 37.58H37.58" />
      <path data-name="Path 230" d="M63.727 25.262L53.167 37.58l10.56 12.318" />
      <path data-name="Line 138" d="M1.5 37.58h36.08" />
      <path data-name="Path 231" d="M11.435 25.262l10.56 12.318-10.56 12.318" />
      <path
        data-name="Rectangle 9"
        d="M37.58 26.886L48.274 37.58 37.58 48.275 26.886 37.58z"
      />
    </g>
  </svg>
);

export const Leaf = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="35.651" height="75.224">
    <g
      data-name="Group 75"
      fill="none"
      stroke="#e69d28"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
    >
      <path
        data-name="Path 258"
        d="M17.844 58.758c-8.8 0-15.915-3.924-15.915-19.748S11.497 7.869 17.844 1.5c6.347 6.369 15.915 21.686 15.915 37.51s-7.115 19.748-15.915 19.748"
      />
      <path data-name="Line 163" d="M17.825 1.5v72.224" />
      <path data-name="Line 164" d="M31.918 28.435L17.825 41.369" />
      <path data-name="Line 165" d="M3.732 28.435l14.093 12.934" />
      <g data-name="Group 73">
        <path data-name="Line 166" d="M33.531 35.904L17.825 50.318" />
        <path data-name="Line 167" d="M2.12 35.904l15.706 14.414" />
      </g>
      <g data-name="Group 74">
        <path data-name="Line 168" d="M30.107 21.322l-12.26 11.251" />
        <path data-name="Line 169" d="M5.544 21.282l12.303 11.291" />
      </g>
    </g>
  </svg>
);

export const Flower = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="34.124" height="75.515">
    <g
      data-name="Group 101"
      fill="none"
      stroke="#b6d3bb"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
    >
      <path data-name="Line 196" d="M17.083 36.108l-.038 37.905" />
      <path data-name="Line 197" d="M30.47 49.315L17.062 61.621" />
      <path data-name="Line 198" d="M3.654 49.315l13.408 12.306" />
      <g data-name="Group 100">
        <path data-name="Line 199" d="M32.004 56.422L17.062 70.136" />
        <path data-name="Line 200" d="M2.119 56.422l14.942 13.714" />
      </g>
      <path
        data-name="Path 298"
        d="M17.083 36.108c-8.369 0-15.142-3.733-15.142-18.788A43.681 43.681 0 013.605 5.704h0c2.59 3.172 6.739 6.423 6.739 13.058 0-6.635 4.106-14.09 6.7-17.262 2.591 3.172 6.781 10.627 6.781 17.262 0-6.635 4.148-9.886 6.739-13.058h0a43.721 43.721 0 011.663 11.616c0 15.055-6.772 18.788-15.141 18.788"
      />
    </g>
  </svg>
);

export const Sun = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="76.228" height="76.232">
    <g
      data-name="Group 68"
      transform="translate(-236.73 -614.553)"
      strokeWidth="3"
      stroke="#fdd976"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <circle
        data-name="Ellipse 4"
        cx="16.502"
        cy="16.502"
        r="16.502"
        transform="translate(258.326 636.181)"
      />
      <path data-name="Line 155" d="M310.809 616.706l-12.921 12.921" />
      <path data-name="Line 156" d="M251.776 675.739l-12.925 12.925" />
      <path data-name="Line 157" d="M310.837 688.633l-12.921-12.921" />
      <path data-name="Line 158" d="M251.804 629.6l-12.925-12.925" />
      <path data-name="Line 159" d="M310.836 652.683h-3.724" />
      <path data-name="Line 160" d="M242.255 652.683h-3.406" />
      <path data-name="Line 161" d="M274.829 688.664v-3.553" />
      <path data-name="Line 162" d="M274.829 620.255v-3.549" />
    </g>
  </svg>
);

export const Emblem = () => (
  <svg width="41" height="41" xmlns="http://www.w3.org/2000/svg">
    <g
      transform="translate(1 1)"
      stroke="#FFF"
      strokeWidth="3"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M2.239 11.58a7.994 7.994 0 002.586 6.568c3.938 3.318 10.931 1.485 15.635-4.1 1.94-2.3 2.406-5.1 1.041-6.251-1.365-1.151-4.044-.217-5.984 2.086-4.7 5.583-5.325 12.785-1.387 16.1 3.086 2.6 9.037.6 13.306-4.462 1.761-2.09 2.321-4.514 1.251-5.416-1.07-.902-3.364.062-5.124 2.152-4.269 5.066-5.227 11.269-2.141 13.869 3.086 2.6 9.823 1.809 14.062-3.667" />
      <circle cx="19.214" cy="19.214" r="18.714" />
    </g>
  </svg>
);

export const Logo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40.429" height="40.429">
    <g data-name="Group 3">
      <g data-name="Group 2" transform="translate(-14.799 -14.832)">
        <g data-name="Group 1">
          <path
            data-name="Path 1"
            d="M18.038 27.412a7.994 7.994 0 002.586 6.568c3.938 3.318 10.931 1.485 15.635-4.1 1.94-2.3 2.406-5.1 1.041-6.251s-4.044-.217-5.984 2.086c-4.7 5.583-5.325 12.785-1.387 16.1 3.086 2.6 9.037.6 13.306-4.462 1.761-2.09 2.321-4.514 1.251-5.416s-3.364.062-5.124 2.152c-4.269 5.066-5.227 11.269-2.141 13.869s9.823 1.809 14.062-3.667"
            fill="none"
            stroke="#040405"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
        </g>
        <circle
          data-name="Ellipse 1"
          cx="18.714"
          cy="18.714"
          r="18.714"
          transform="translate(16.299 16.332)"
          strokeWidth="3"
          stroke="#040405"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </g>
    </g>
  </svg>
);
