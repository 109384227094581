import React from "react";
import { Button, Radio } from "../Inputs/Inputs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Formik, Form } from "formik";

import Modal from "../common/Modal/Modal";

import styles from "../common/Modal/Modal.module.scss";

const ProposalPostModal = ({
  data,
  confirmationModal,
  setConfirmationModal,
  handleReportSubmission,
  setOpenAnnouncement,
}) => {
  const { flag_reasons } = data;

  if (!confirmationModal) return null;
  return (
    <Modal>
      <div className={styles.proposalModalWrapper}>
        <p>
          <AiOutlineCloseCircle
            onClick={() => {
              setConfirmationModal(false)
              setOpenAnnouncement(false)
            }}
            className={styles.closeButton}
          />
        </p>
        <p>Ilmoita viestistä</p>
        <Formik
          initialValues={{
            reason: "",
          }}
          onSubmit={handleReportSubmission}
        >
          {({ values }) => {
            return (
              <Form className={styles.radioListWrapper}>
                {flag_reasons?.map(reason => (
                  <Radio
                    key={reason.value}
                    formik
                    value={reason.value}
                    id={reason.value}
                    label={reason.name}
                    name="reason"
                    greenBackground
                  />
                ))}
                <div className={styles.flagModalButton}>
                  <Button
                    onClick={() => {
                      setConfirmationModal(false)
                      setOpenAnnouncement(false)
                    }}
                    type="submit"
                    tealButton
                  >
                    Peruuta
                  </Button>
                  <Button type="submit" tealButton disabled={!values.reason}>
                    Raportoi
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default ProposalPostModal;
