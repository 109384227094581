import React from "react";

const PageAlignment = ({ children }) => {
  const styles = {
    margin: "32px 0 16px 0",
    padding: "0 16px",
  };
  return <div style={styles}>{children}</div>;
};

export default PageAlignment;
