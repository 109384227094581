
export function postContainsSearchQuery(post, searchQuery) {
    if (searchQuery.length === 0) {
        return false
    }

    const regex = new RegExp(searchQuery, "i")

    if (post.title.match(regex) || post.content.match(regex)) {
        return true
    }

    return false
}