import React, {useContext} from "react";
import Modal from "../common/Modal/Modal";
import { Button } from "../Inputs/Inputs";
import SecurityContext from "../Context/SecurityContext";

import styles from "../common/Modal/Modal.module.scss";

export default function DeleteAccountModal({ 
    isOpen,
    setDeleteModalOpen,
    resetToken
}) {
    const { api } = useContext(SecurityContext);

    const deleteAccount = () => {
        api.deleteAccount()
        .then(res => {
            resetToken()
        })
    }

    return (
        <>
            {isOpen && (
                <Modal>
                    <div className={styles.proposalModalWrapper}>
                        <p>Haluatko varmasti poistaa tilisi?</p>

                        <div className={styles.flagModalButton}>
                            <Button onClick={() => setDeleteModalOpen(false)} blueButton>Peruuta</Button>
                            <Button onClick={() => deleteAccount()} redButton>Poista</Button>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    )
}