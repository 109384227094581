import React from "react";
import styles from "./OpasNewsLetterBox.module.scss";
import { Link } from "react-router-dom";

const OpasNewsLetterBox = ({
  itemTitleStart,
  itemSubTitleStart,
  itemIcon,
  releaseInfo,
  releaseTime,
  releaseTimeTop,
  releaseInfoDown,
  url,
}) => {
  return (
    <Link
      to={url}
      target="_blank"
      rel="noopener noreferrer"
      className={`${styles.newsItemOpas}`}
    >
      <div>
        <p className={styles.itemTitle}>{itemTitleStart}</p>
        <p>Julkaistu {itemSubTitleStart}</p>
      </div>
      <div>
        <div className={styles.releaseData}>
          <p>{releaseTimeTop}</p>
          <p>{releaseInfoDown}</p>
        </div>
        <div className={styles.releaseData}>
          <p>{releaseTime}</p>
          <p>{releaseInfo}</p>
        </div>
      </div>
      {itemIcon && <div>{itemIcon}</div>}
    </Link>
  );
};

export default OpasNewsLetterBox;
