
export function findFieldValue(pollForm, thePoll) {
    if (!thePoll.dynamic_form_submission) {
        return ''
    }

    const formFieldId = pollForm.id

    const value = thePoll.dynamic_form_submission.form_field_values.find(formField => {
      return formField?.form_field_id === formFieldId
    })

    if (value) {
      return value.value
    }

    return ''
}