import React, { useEffect, useState } from "react";
import styles from "./Header.module.scss";
import { FiSettings } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux'

import hkLogo from '../../assets/hk_logo.png'

const Header = ({ title, emblem }) => {
  const currentComplexName = useSelector((state) => state.currentComplex.name)

  const [complexName, setComplexName] = useState('')

  useEffect(() => {
    setComplexName(currentComplexName)
  }, [currentComplexName])

  return (
    <header className={styles.header}>
      <div className={styles.container}>

        <div className={styles.headerContent}>
          <div className={styles.hkLogo}>
            <img alt="Helsingin kaupunki -logo" src={hkLogo} />
          </div>

          <div className={styles.titleAndAdressContainer}>
            <div className={styles.titleAndAdress}>
              <div className={styles.titleContainer}>
                <p>{title}</p>
              </div>

              <p>{complexName}</p>
            </div>
          </div>

          <div className={styles.settings}>
            <Link to="/settings">
              <FiSettings />
            </Link>
          </div>

        </div>
      </div>
    </header>
  );
};

export default Header;
