import React from "react";
import styles from "./Tag.module.scss";
import classNames from "classnames";

const Tag = ({
  icon,
  label,
  value,
  center,
  small,
  large,
  teal,
  yellow,
  blue,
  orange,
  grey,
  whiteblue,
  blackteal,
  lightblue,
  outlineTeal,
  transparentColor,
}) => {
  const tagClassNames = classNames(styles.tag, {
    [styles.small]: !large || small,
    [styles.large]: large,
    [styles.center]: center,
    [styles.teal]: !yellow || teal,
    [styles.yellow]: yellow,
    [styles.blue]: blue || teal,
    [styles.whiteblue]: whiteblue,
    [styles.blackteal]: blackteal,
    [styles.lightblue]: lightblue,
    [styles.orange]: orange,
    [styles.grey]: grey,
    [styles.outlineTeal]: outlineTeal,
    [styles.transparentColor]: transparentColor,
  });

  return (
    <div className={tagClassNames}>
      {icon && <div className={styles.icon}>{icon}</div>}
      {label && <span className={styles.label}>{label}</span>}
      {(value || value === 0) && <span className={styles.value}>{value}</span>}
    </div>
  );
};

export default Tag;
