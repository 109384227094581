import React, { useContext, useState } from "react";

import styles from "./WelcomeModal.module.scss";
import SecurityContext from "../Context/SecurityContext";
import { FiBookOpen, FiHome, FiUsers, FiFeather } from "react-icons/fi";
import { Button } from "../Inputs/Inputs";
import Modal from "../common/Modal/Modal";

const WelcomeModal = ({
  displayInfoModal,
  setDisplayInfoModal,
  user,
}) => {
  const { api } = useContext(SecurityContext);

  const [termsAccepted, setTermsAccepted] = useState(false)
  const [firstClick, setFirstClick] = useState(false)

  const handleWelcomeInfo = () => {
    setFirstClick(true)

    if (termsAccepted === false) {
      return
    }

    const payLoad = {
      seen_welcome_info_at: user?.seen_welcome_info_at,
    };
    return api
      .seenWelcomeModal(payLoad)
      .then(data => {
        setDisplayInfoModal(false);
      })
      .catch(err => {
        if (err.errors) {
          setDisplayInfoModal(false);
        }
      });
  };

  if (!displayInfoModal) return null;

  return (
    <Modal>
      <h1>Tervetuloa Hetaan</h1>

      <div className={styles.infoContainer}>
        <div className={styles.iconWrapper}>
          <FiHome className={styles.infoIcon} />
        </div>
        <p>
          <strong>Kotisivulla</strong> näet Hekan ja asukastoimikunnan
          ajankohtaisia tiedotuksia
        </p>
        <div className={styles.iconWrapper}>
          <FiBookOpen className={styles.infoIcon} />
        </div>
        <p>
          <strong>Asukkaan oppaasta</strong> löydät tietoja ja neuvoja asumiseen
        </p>
        <div className={styles.iconWrapper}>
          <FiUsers className={styles.infoIcon} />
        </div>
        <p>
          <strong>Oma talo</strong> -sivulla voit osallistua yhteisösi
          toimintaan vastaamalla asukastoimikunnan kyselyihin ja tekemällä
          ehdotuksia
        </p>
        <div className={styles.iconWrapper}>
          <FiFeather className={styles.infoIcon} />
        </div>
        <p>
          <strong>Ekoekspertissä</strong> keräät pisteitä itsellesi ja
          yhteisöllesi suorittamalla ekoteemaisia haasteita
        </p>
      </div>

      <div className={styles.terms}>
        <div>
          <input type="checkbox"
            onChange={() => {
              setTermsAccepted(!termsAccepted)
            }}
            checked={termsAccepted}
          />

          <div>
            Hyväksyn palvelun{" "}
            <a href="https://www.hekaoy.fi/asumisen-aikana/asukastoiminta/heta-sovellus-heka-talo-taskussa/#heta-kayttoehdot" target="_blank">käyttöehdot</a>
          </div>
        </div>

        {(termsAccepted === false && firstClick === true) && (
          <p>Ehdot täytyy hyväksyä!</p>
        )}
      </div>

      <div className={styles.modalButton}>
        <Button tealButton onClick={handleWelcomeInfo}>
          Jatka sovellukseen
        </Button>
      </div>
    </Modal>
  );
};

export default WelcomeModal;
