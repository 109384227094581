import React from "react";
import { FiSearch } from "react-icons/fi";
import styles from "./SearchBar.module.scss";

const SearchBar = ({ searchDescription, searchQuery, setSearchQuery }) => {
  const handleSearchQuery = e => {
    setSearchQuery(e.target.value);
  };

  const closeKeyboard = e => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  }

  return (
    <form
      className={styles.searchBox}
      onSubmit={event => event.preventDefault()}
    >
      <FiSearch style={{ fontSize: "1.2rem" }} />
      <div className={styles.searchContainer}>
        <input
          type="search"
          name="q"
          size="80"
          aria-label="Search through site content"
          value={searchQuery}
          className={styles.searchInput}
          placeholder={searchDescription}
          onChange={handleSearchQuery}
          onKeyDown={(e) => { closeKeyboard(e) }}
        />
      </div>
    </form>
  );
};

export default SearchBar;
