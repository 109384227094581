import React, { useContext, useEffect, useState } from "react";
import DropDownBox from "../components/common/DropDownBox/DropDownBox";
import OpasNewsLetterBox from "../components/common/EventSchedules/OpasNewsLetterBox/OpasNewsLetterBox";
import PageAlignment from "../components/common/PageAlignment/PageAlignment";
import { FaArrowRight } from "react-icons/fa";
import PageContainer from "../components/PageContainer/PageContainer";
import PageSubHeader from "../components/PageSubHeader/PageSubHeader";
import SearchBar from "../components/SearchBar/SearchBar";
import SecurityContext from "../components/Context/SecurityContext";

import styles from "./ResidentGuide.module.scss";

const ResidentGuide = () => {
  const { api } = useContext(SecurityContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [opasFeed, setOpasFeed] = useState({});
  const { data } = opasFeed;

  useEffect(() => {
    let ignore = false;
    if (!ignore && api) {
      api
        .getOpasContent()
        .then(response => {
          if (response && response.data) {
            setOpasFeed({ data: response.data, error: null });
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
    return () => (ignore = true);
  }, [api]);

  return (
    <PageContainer>
      <PageSubHeader pageSubTitle="Asukkaan Opas" />
      <PageAlignment>

        <div className={styles.emptyBox}></div>

        <SearchBar
          searchDescription="Hae ohjeistuksia"
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />

        {data?.link_headings?.length > 0 && (
          <div className={styles.opasNewsContainer}>
            {data?.link_headings
              ?.filter(list => {
                if (searchQuery === "") {
                  return list;
                } else if (
                  list.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                  list.published_on
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase())
                ) {
                  return list;
                }
              })
              .map(linkHeading => (
                <OpasNewsLetterBox
                  key={linkHeading.id}
                  url={{ pathname: linkHeading?.link }}
                  itemTitleStart={linkHeading?.title}
                  itemSubTitleStart={linkHeading?.published_on}
                  itemIcon={<FaArrowRight />}
                />
              ))}
          </div>
        )}

        {data?.headings.map(heading => (
          <DropDownBox
            key={heading.id}
            title={heading.title}
            posts={heading?.posts}
            searchQuery={searchQuery}
            useBorder={true}
          />
        ))}
      </PageAlignment>
    </PageContainer>
  );
};

export default ResidentGuide;
