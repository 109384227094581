import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { App as CapApp } from "@capacitor/app";

const AppUrlListener = () => {
  let history = useHistory();
  useEffect(() => {
    CapApp.addListener("appUrlOpen", data => {
      const slug = data.url.split(".fi").pop();
      if (slug) {
        history.push(slug);
      }
    });
  }, []);

  return null;
};

export default AppUrlListener;
