import React, { useState, useEffect, useContext } from "react";
import { TabPage } from "../PageContainer/PageContainer";
import ChallengeContainer from "../ChallengeContainer/ChallengeContainer";
import SecurityContext from "../Context/SecurityContext";
import { getSeasonTitles, getYear, getExtendedList } from "./ChallengesHelper";

export default function OpenChallenges({ ownChallenges, setOwnChallenges }) {
  const { api } = useContext(SecurityContext);

  const [season, setSeason] = useState({});
  const [communalChallenges, setCommunalChallenges] = useState({});
  //  const [ownChallenges, setOwnChallenges] = useState({});
  const [communalLoading, setCommunalLoading] = useState(false);
  const [ownLoading, setOwnLoading] = useState(false);

  useEffect(() => {
    let ignore = false;
    if (!ignore && api) {
      setCommunalLoading(true);
      api
        .getCommunalChallenges()
        .then(response => {
          if (response) {
            const communal = getExtendedList(response.data, "communal");
            // console.log("communal challenges", communal);
            setCommunalChallenges({
              data: communal,
              error: null,
            });
          }
          setCommunalLoading(false);
        })
        .catch(err => {
          console.log(err);
          setCommunalChallenges({
            data: null,
            error: "Tietojen haku epäonnistui",
          });
          setCommunalLoading(false);
        });
    }
    return () => (ignore = true);
  }, [api]);

  useEffect(() => {
    let ignore = false;
    if (!ignore && api) {
      setOwnLoading(true);
      api
        .getPersonalChallenges()
        .then(response => {
          if (response) {
            const own = getExtendedList(response.data, "own");
            // console.log("personal challenge", own);
            setOwnChallenges({ data: own, error: null });
          }
          setOwnLoading(false);
        })
        .catch(err => {
          console.log(err);
          setOwnChallenges({
            data: null,
            error: "Tietojen haku epäonnistui",
          });
          setOwnLoading(false);
        });
    }
    return () => (ignore = true);
  }, [api]);

  useEffect(() => {
    let ignore = false;
    if (!ignore && api) {
      api
        .getSeason()
        .then(data => {
          const result = data.start_date && getSeasonTitles(data.start_date);
          if (result) {
            const year = getYear(data.start_date);
            setSeason({
              name: result.name,
              period: result.period + " " + year,
            });
          }
        })
        .catch(err => {
          console.log(err);
          setSeason({});
        });
    }
    return () => (ignore = true);
  }, [api]);

  return (
    <TabPage>
      <ChallengeContainer
        type={season.name}
        title="Kauden yhteisöhaasteet"
        subtitle={season.period}
        challenges={communalChallenges}
        loading={communalLoading}
      />
      <ChallengeContainer
        title="Omat haasteet"
        challenges={ownChallenges}
        loading={ownLoading}
      />
    </TabPage>
  );
}
