
export function createWeekDayTags(weekdays) {

    const tags = weekdays.map(day => {
        let dayTag = null

        switch (day) {
            case 'monday':
                dayTag = <p key={day}>maanantai</p>
                break;
            case 'tuesday':
                dayTag = <p key={day}>tiistai</p>
                break;
            case 'wednesday':
                dayTag = <p key={day}>keskiviikko</p>
                break;
            case 'thursday':
                dayTag = <p key={day}>torstai</p>
                break;
            case 'friday':
                dayTag = <p key={day}>perjantai</p>
                break;
            case 'saturday':
                dayTag = <p key={day}>lauantai</p>
                break;
            case 'sunday':
                dayTag = <p key={day}>sunnuntai</p>
                break;
            default:
        }

        return dayTag
    })

    return tags
}

export function createDateString(dateISO) {
    const date = new Date(dateISO)

    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
}