
export function titleOrPostsContainsSearchQuery(title, posts, searchQuery) {    
    if (searchQuery.length === 0 || !posts) {
        return false
    }

    const regex = new RegExp(searchQuery, "i")

    if (title.match(regex)) {
        return true
    }

    let result = false

    posts.forEach(post => {
        if (post.title.match(regex) || post.content.match(regex)) {
            result = true
        }
    });

    return result
}