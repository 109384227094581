import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import PageAlignment from "../components/common/PageAlignment/PageAlignment";
import { Button } from "../components/Inputs/Inputs";
import PageContainer from "../components/PageContainer/PageContainer";
import PageHeadings from "../components/PageHeadings/PageHeadings";
import SearchBar from "../components/SearchBar/SearchBar";
import ProposalPost from "../components/ProposalPost/ProposalPost";
import PageSubHeader from "../components/PageSubHeader/PageSubHeader";
import FrontPageEvent from "../components/common/EventSchedules/FrontPageEvent/FrontPageEvent";
import BuildingPoll from "../components/BuildingPoll/BuildingPoll";
import Spinner from "../components/Spinner/Spinner";
import SecurityContext from "../components/Context/SecurityContext";
import DropDownBox from "../components/common/DropDownBox/DropDownBox";

import {
  createAsukasToimikuntaPosts
} from './Proposal.utils'

import _ from "lodash";

import styles from "./Proposal.module.scss";

const Proposal = ({ pageContext, loading }) => {
  const { api } = useContext(SecurityContext);

  const [searchQuery, setSearchQuery] = useState("");
  const [ownBuildingComplexData, setOwnBuildingComplexData] = useState({});
  const location = useLocation();
  const { data } = ownBuildingComplexData;

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      api
        .getProposal()
        .then(response => {
          if (response && response.data) {
            setOwnBuildingComplexData({
              data: response.data,
              error: null,
            });
          }
        })
    }
    return () => (ignore = true);
  }, [api, setOwnBuildingComplexData]);

  const posts = createAsukasToimikuntaPosts(
    ownBuildingComplexData?.data?.housing_committee_message,
    ownBuildingComplexData?.data?.housing_committee_contact,
    ownBuildingComplexData?.data?.year_clock_info,
    ownBuildingComplexData?.data?.tasks_info,
  )

  return (
    <PageContainer>
      <PageSubHeader pageSubTitle="Oma talo" />
      <PageAlignment>

        {ownBuildingComplexData?.data?.total_events > 0 && (
          <Link to="/community/events">
            <Button
              blueButton
            >
              Katso kaikki tapahtumat ({ownBuildingComplexData.data.total_events})
            </Button>
          </Link>
        )}

        {ownBuildingComplexData?.data?.next_three_events.length > 0 && (
          <div className={styles.eventContainer}>
            {ownBuildingComplexData?.data?.next_three_events?.map(event => {
              return (
                <FrontPageEvent
                  key={event.id}
                  eventDate={event.date}
                  endDate={event.end_date}
                  startTime={event.start_time}
                  endTime={event.end_time}
                  dateString={event.date_string}
                  title={event.title}
                  description={event.description}
                  richTextDescription={event.rich_text_description_html}
                />
              );
            })}
          </div>
        )}

        {posts.length > 0 && (
          <div className={styles.housingCommitteeContainer}>
            <DropDownBox
              title={"ASUKASTOIMIKUNTA"}
              posts={posts}
              searchQuery=""
              useBorder={true}
            />
          </div>
        )}

        <BuildingPoll
          proposalData={ownBuildingComplexData}
          ownBuildingComplexData={ownBuildingComplexData}
        />
        <PageHeadings headingTitle="Asukkaiden ehdotukset" />
        <Link to={{ pathname: `${pageContext.page}/suggestions` }}>
          <Button tealButton>Ehdota asukastoimikunnalle</Button>
        </Link>
        {data?.user_proposals.length === 0 ? null : (
          <SearchBar
            searchDescription="Hae ehdotuksia"
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        )}
        {loading ? (
          <Spinner>Ladataan ...</Spinner>
        ) : (
          <ProposalPost
            searchQuery={searchQuery}
            location={location}
            ownBuildingComplexData={ownBuildingComplexData}
          />
        )}
      </PageAlignment>
    </PageContainer>
  );
};

export default Proposal;
