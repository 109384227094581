import React from "react";
import styles from "./Container.module.scss";
import classNames from "classnames";

const Container = ({ size, relative, background, center, children }) => {
  const containerClassNames = classNames(styles.container, {
    [styles.medium]: size === 20,
    [styles.large]: size === 24,
    [styles.relative]: relative,
    [styles.grey]: background === "grey",
    [styles.center]: center
  });

  return <div className={containerClassNames}>{children}</div>;
};

export default Container;
