
export function filterWithSearchQuery(
    feed,
    searchQuery
) {
    if (searchQuery.length === 0) {
        return feed
    }

    return feed?.filter(item => {
        const regex = new RegExp(searchQuery, "i")

        if (item.content.match(regex) || item.title.match(regex)) {
            return item
        }
    })
}
