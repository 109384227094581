import React, { useState, useEffect, useContext } from "react";
import { Route } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

import PageContainer from "../components/PageContainer/PageContainer";
import { getExtendedList } from "../components/Challenges/ChallengesHelper";
import OpenChallenges from "../components/Challenges/OpenChallenges";
import SecurityContext from "../components/Context/SecurityContext";
import CompletedChallenges from "../components/Challenges/CompletedChallenges";
import TabContainer from '../components/PageContainer/PageContainer'
import PageSubHeader from "../components/PageSubHeader/PageSubHeader";
import Tag from "../components/Tag/Tag";
import ChallengeContainer from "../components/ChallengeContainer/ChallengeContainer";

const Challenges = ({
  pageContext,
  activeTabIndex,
  challengesHistory,
  setChallengesHistory,
  user,
}) => {
  const [ownChallenges, setOwnChallenges] = useState({});
  const [dailyChallenge, setDailyChallenge] = useState({});
  const [dailyLoading, setDailyLoading] = useState(false);
  const { api } = useContext(SecurityContext);

  const tabs = {
    active: activeTabIndex,
    page: pageContext.page,
    color: pageContext.color,
    tabs: [
      {
        to: `${pageContext.page}/open-challenges`,
        label: "Avoimet haasteet",
      },
      {
        to: `${pageContext.page}/completed-challenges`,
        label: "Suoritetut haasteet",
      },
    ],
  };

  const dailyDate =
    dailyChallenge.data &&
    dailyChallenge.data[0] &&
    dailyChallenge.data[0].date;
  const dailyCompleted =
    dailyChallenge.data &&
    dailyChallenge.data[0] &&
    dailyChallenge.data[0].completed;

  useEffect(() => {
    let ignore = false;
    if (!ignore && api) {
      setDailyLoading(true);
      api
        .getDailyChallenge()
        .then(response => {
          if (response && response.data) {
            const own = getExtendedList([response.data], "own");
            setDailyChallenge({ data: own, error: null });
          }
          setDailyLoading(false);
        })
        .catch(err => {
          setDailyChallenge({
            data: null,
            error: "Tietojen haku epäonnistui",
          });
          setDailyLoading(false);
        });
    }
    return () => (ignore = true);
  }, [api]);

  return (
    <PageContainer>
      <PageSubHeader
        pageSubTitle={user?.username}
        challengeScore={
          ownChallenges?.data && ownChallenges?.data[0]?.personalPoints
        }
        completedChallenges={
          ownChallenges?.data && ownChallenges?.data[0]?.completions
        }
        challengeRank="Sijoituksesi yhteisössäsi"
        rankNumber={<Tag value={10} blackteal />}
        challengePerformance="Sijoitukset"
        arrowIcon={<FaArrowRight />}
      />

      <TabContainer
        tabs={tabs}
        tabBarWithMargin
      />

      <ChallengeContainer
        type="daily"
        title="Päivän haaste"
        subtitle={dailyDate}
        challenges={dailyChallenge}
        loading={dailyLoading}
        dailyCompleted={dailyCompleted}
      />

      <Route exact path={`${pageContext.page}/open-challenges`}>
        <OpenChallenges
          ownChallenges={ownChallenges}
          setOwnChallenges={setOwnChallenges}
        />
      </Route>
      
      <Route exact path={`${pageContext.page}/completed-challenges`}>
        <CompletedChallenges
          challengesHistory={challengesHistory}
          setChallengesHistory={setChallengesHistory}
        />
      </Route>
    </PageContainer>
  );
};

export default Challenges;
