const seasons = [
  { start: 0, name: "winter", period: "tammi–maalis" },
  { start: 3, name: "spring", period: "huhti-kesä" },
  { start: 6, name: "summer", period: "heinä–syys" },
  { start: 9, name: "autumn", period: "loka–joulu" },
];

function getStartMonth(date) {
  const startDate = new Date(date);
  return startDate.getMonth();
}

function getSeasonTitles(date) {
  const month = getStartMonth(date);
  return seasons.find(s => s.start === month);
}

function getYear(date) {
  const start = new Date(date);
  return start.getFullYear();
}

function calcPoints(list, type) {
  if (list) {
    return list.reduce((acc, val) => {
      if (val && val.type === type) {
        return acc + val.value;
      }
      return acc;
    }, 0);
  }
}

function getExtendedList(list = [], type) {
  const extendedList = list;

  if (type === "communal") {
    extendedList.forEach(item => {
      item.icon = "flag";
      item.communalPoints = calcPoints(item.rewards, "communal");
      item.personalPoints = calcPoints(item.rewards, "personal");
    });
  } else if (type === "own") {
    extendedList.forEach(item => {
      item.icon = "hand";
      item.personalPoints = calcPoints(item.rewards, "personal");
    });
  }

  return extendedList;
}

export { getSeasonTitles, getYear, calcPoints, getExtendedList };

/*item.form = {
      id: "some-form-id-123",
      form_fields: [
        {
          id: "123",
          type: "text",
          label: "Describe your week",
          placeholder: "This week I...",
          min: null,
          max: null,
          required: true,
        },
        {
          id: "456",
          type: "integer",
          label: "Rate your week",
          placeholder: "1-10",
          min: 1,
          max: 10,
          required: true,
        },
        {
          id: "789",
          type: "date",
          label: "Best day of the week",
          placeholder: null,
          min: null,
          max: null,
          required: true,
        },
        {
          id: "156",
          type: "radio",
          label: "Choose one",
          placeholder: null,
          min: null,
          max: null,
          required: true,
          options: ["only 1 can be chosen", "if it's a radio button"],
        },
      ],
    };*/
/*const ownChallenges = [
  {
    completionType: "multiSelect",
    completionQuestion: "Lorem ipsum?",
    completionOptions: [
      { id: "completionOptionMulti1", value: "Lorem ipsum 1" },
      { id: "completionOptionMulti2", value: "Lorem ipsum 2" },
      { id: "completionOptionMulti3", value: "Lorem ipsum 3" }
    ]
  },
];
*/
