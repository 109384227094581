import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";

import SecurityContext from "../Context/SecurityContext";
import styles from "../ResetPassword/ResetPassword.module.scss";
import { TextInput, InputError, Button } from "../Inputs/Inputs";
import PageContainer from "../PageContainer/PageContainer";
import Container from "../Container/Container";

export default function ChangePassword() {
  const { api } = useContext(SecurityContext);

  const [authParams, setAuthParams] = useState(false);
  const [pswdIsReset, setPswdIsReset] = useState(false);

  useEffect(() => {
    let ignore = false;
    if (!ignore && api && api.accessToken) {
      const params = {
        "access-token": api.accessToken,
        "token-type": api.tokenType,
        client: api.client,
        uid: api.uid,
      };
      setAuthParams(params);
    }
    return () => (ignore = true);
  }, [api]);

  return (
    <PageContainer>
      <Container>
        <div className={styles.back}>
          <Link to="/settings">
            <ArrowLeftIcon />
          </Link>
        </div>
        {pswdIsReset ? (
          <div className={styles.text}>Salasana on vaihdettu.</div>
        ) : (
          <ResetPasswordForm
            api={api}
            authParams={authParams}
            setPswdIsReset={setPswdIsReset}
          />
        )}
      </Container>
    </PageContainer>
  );
}

function ResetPasswordForm({ api, authParams, setPswdIsReset }) {
  const [error, setError] = useState("");

  const PasswordSchema = Yup.object().shape({
    current_password: Yup.string()
      .min(6, "Oltava vähintään 6 merkkiä")
      .required("Pakollinen tieto"),
    new_password: Yup.string()
      .min(6, "Oltava vähintään 6 merkkiä")
      .required("Pakollinen tieto"),
    new_password_again: Yup.string()
      .oneOf([Yup.ref("new_password"), null], "Salasanat eivät täsmää")
      .min(6, "Oltava vähintään 6 merkkiä")
      .required("Pakollinen tieto"),
  });

  const handleSubmit = (values, setSubmitting) => {
    setSubmitting(true);
    setError("");

    const requestParams = {
      password: values.new_password,
      password_confirmation: values.new_password_again,
      current_password: values.current_password,
    };

    api
      .resetPassword(authParams, requestParams)
      .then(data => {
        console.log("reset password", data);
        setSubmitting(false);
        if (data && data.success) {
          setPswdIsReset(true);
        } else if (data && data.errors) {
          setError(data.errors.full_messages && data.errors.full_messages[0]);
        }
      })
      .catch(err => {
        setSubmitting(false);
        if (err.errors) {
          setError(err.errors.full_messages && err.errors.full_messages[0]);
        }
      });
  };

  return (
    <Formik
      initialValues={{
        current_password: "",
        new_password: "",
        new_password_again: "",
      }}
      validationSchema={PasswordSchema}
      onSubmit={(values, { setSubmitting }) =>
        handleSubmit(values, setSubmitting)
      }
    >
      {props => {
        const { isValid, isSubmitting } = props;

        return (
          <Form>
            <div className="margin-1 margin-bottom">
              <TextInput
                formik
                required
                type="password"
                name="current_password"
                label="Nykyinen salasana"
              />
              <ErrorMessage name="current_password" component={InputError} />
              <TextInput
                formik
                required
                type="password"
                name="new_password"
                label="Uusi salasana"
              />
              <ErrorMessage name="new_password" component={InputError} />
              <TextInput
                formik
                required
                type="password"
                name="new_password_again"
                label="Toista uusi salasana"
              />
              <ErrorMessage name="new_password_again" component={InputError} />
              {error && <InputError>{error}</InputError>}
            </div>

            <div className={styles.submit}>
              <Button blueButton type="submit" disabled={isSubmitting || !isValid}>
                Vaihda salasana
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
