import React, { useState, useEffect, useContext } from "react";
import { FiThumbsUp } from "react-icons/fi";
import SecurityContext from "../../Context/SecurityContext";
import styles from "../ProposalPost.module.scss";

export default function UpvoteContainer({ data, proposalType }) {
    const { api } = useContext(SecurityContext);

    const {
        upvoted,
        id,
        total_upvotes: totalUpvotes,
    } = data

    const [isUpvoted, setIsUpvoted] = useState(false)
    const [numberOfLikes, setNumberOfLikes] = useState(0)

    useEffect(() => {
        setIsUpvoted(upvoted)
        setNumberOfLikes(totalUpvotes)
    }, [])

    const handleLike = () => {
        if (proposalType === 'proposal') {
            api.upVoteProposal(id)
        } else {
            api.upVoteProposalResponse(id)
        }

        setNumberOfLikes(isUpvoted ? numberOfLikes - 1 : numberOfLikes + 1)
        setIsUpvoted(!isUpvoted)
    }

    return (
        <div className={`${styles.proposalActions} ${styles.withMargin}`}>
            <h4>Kannatatko tätä ehdotusta?</h4>
            <div className={isUpvoted ? `${styles.blueColor} ${styles.upvoteContainer}` : `${styles.defaultColor} ${styles.upvoteContainer}`}>
                <FiThumbsUp
                    className={`${styles.thumbIcon}`}
                    onClick={handleLike}
                />
                <div className={styles.numberOfLikes}>
                    <p>{numberOfLikes}</p>
                </div>
            </div>
        </div>
    )
}