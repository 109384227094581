import React from "react";

import {
    createWeekDayTags,
    createDateString,
} from './EventsContainer.utils'

import styles from './EventsContainer.module.scss'

export default function EventsContainer({ events, type }) {

    const allEvents = events.map(event => {

        return (
            <div key={event.id} className={styles.event}>
                <p className={styles.title}>{event.title}</p>

                {type === 'recurring' && (
                    <div className={styles.weekdayTags}>
                        {createWeekDayTags(event.days_of_the_week)}
                    </div>
                )}

                <div className={styles.startTimeContainer}>
                    <p className={styles.timeTitle}>Aloitus</p>
                    <div className={styles.startTimeInfo}>
                        {event.start_time && (
                            <>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
                                <p>{event.start_time}</p>
                            </>
                        )}

                        {event.date && (
                            <>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                <p>{createDateString(event.date)}</p>
                            </>
                        )}
                    </div>
                </div>

                <div className={styles.endTimeContainer}>
                    <p className={styles.timeTitle}>Lopetus</p>
                    <div className={styles.startTimeInfo}>
                        {event.end_time && (
                            <>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
                                <p>{event.end_time}</p>
                            </>
                        )}

                        {event.end_date && (
                            <>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                <p>{createDateString(event.end_date)}</p>
                            </>
                        )}
                    </div>
                </div>
                <div dangerouslySetInnerHTML={{ __html: event.rich_text_description_html ? event.rich_text_description_html : event.description }} />
            </div>
        )
    })

    return (
        <div className={styles.eventsContainer}>
            {events.length > 0 && (
                <h1>{type === 'recurring' ? 'Toistuvat tapahtumat' : 'Yksittäiset tapahtumat'}</h1>
            )}

            {allEvents}
        </div>
    )
}
