import React from "react";
import { Route, useParams, useHistory } from "react-router-dom";

import PageContainer from "../components/PageContainer/PageContainer";
import OwnAchievements from "../components/Achievements/OwnAchievements";
import CommunityAchievements from "../components/Achievements/CommunityAchievements";
import TabContainer from '../components/PageContainer/PageContainer'
import { BiArrowBack } from "react-icons/bi";

import styles from "./Achievements.module.scss";

const Achievements = ({
  pageContext,
  activeTabIndex,
  ownAchievementsHistory,
  setOwnAchievementsHistory,
  communityAchievementsHistory,
  setCommunityAchievementsHistory,
}) => {
  const tabs = {
    active: activeTabIndex,
    page: pageContext.page,
    color: pageContext.color,
    tabs: [
      {
        to: `${pageContext.page}/achievements/own`,
        label: "Omat suoritukset",
      },
      {
        to: `${pageContext.page}/achievements/community`,
        label: "Yhteisön suoritukset",
      },
    ],
  };

  let history = useHistory();

  return (
    <PageContainer>
      <div className={styles.pageHeader}>
        <button>
          <BiArrowBack
            style={{ fontSize: "1.5rem", cursor: "pointer" }}
            onClick={() => history.push(`${pageContext.page}/open-challenges`)}
          />
        </button>
      </div>

      <div style={{ height: '50px' }} />

      <TabContainer
        tabs={tabs}
      />

      <div style={{ height: '10px' }} />

      <Route exact path={`${pageContext.page}/achievements/own`}>
        <OwnAchievements
          ownAchievementsHistory={ownAchievementsHistory}
          setOwnAchievementsHistory={setOwnAchievementsHistory}
        />

      </Route>
      <Route exact path={`${pageContext.page}/achievements/community`}>
        <CommunityAchievements
          communityAchievementsHistory={communityAchievementsHistory}
          setCommunityAchievementsHistory={setCommunityAchievementsHistory}
        />
      </Route>
    </PageContainer>
  );
};

export default Achievements;
