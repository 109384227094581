import React from "react";
import styles from "./Terms.module.scss";

function Terms({ setTerms }) {
  return (
    <div className={styles.termsOfService}>
      <div className={styles.closeTerms}>
        <button onClick={() => setTerms(false)}>Sulje</button>
      </div>
      <div className={styles.termsOfServiceInner}>
        <h1>Palvelun käyttöehdot</h1>
        <h2>1. Sovelluksen tarjoaja</h2>
        <p>
          Ekoekspertti-sovelluksen tarjoaa käyttöösi ja sitä ylläpitää Helsingin
          kaupungin asunnot Oy (Heka).
        </p>
        <p>
          <table>
            <tr>
              <td>Y-tunnus:</td>
              <td>2379058-6</td>
            </tr>
            <tr>
              <td>Osoite:</td>
              <td>Viipurinkatu 2, 00510 Helsinki</td>
            </tr>
            <tr>
              <td>Sähköposti:</td>
              <td>
                <a href="mailto:heta-sovellus@hekaoy.fi">
                  heta-sovellus@hekaoy.fi
                </a>
              </td>
            </tr>
          </table>
        </p>

        <h2>2. Sinun ja Hekan välinen sopimussuhde</h2>
        <h3>2.1. Sopimuksen syntyminen</h3>
        <p>
          Nämä käyttöehdot koskevat Ekoekspertti-sovelluksen käyttöä ja siinä
          esitettyä sisältöä.
        </p>
        <p>
          Kun käytät sovellustamme, nämä ehdot muodostavat sopimuksen sinun eli
          sovelluksen käyttäjän ja Hekan välille. Sinulla on oikeus käyttää
          sovellusta vain, jos sitoudut noudattamaan näitä käyttöehtoja ja
          sovelluksessa mahdollisesti erikseen annettavia ohjeita.
        </p>
        <p>
          Sovelluksen käyttäminen edellyttää rekisteröitymistä. Sitoudut
          antamaan totuudenmukaiset ja täydelliset tiedot rekisteröityessäsi
          sovelluksen käyttäjäksi sekä pitämään nämä tiedot ajan tasalla.
        </p>
        <p>
          Rekisteröidyt sovellukseen sähköpostiosoitteellasi ja valitsemallasi
          salasanalla. Sinun on vahvistettava rekisteröitymisesi sähköpostiisi
          lähetettävän linkin kautta.
        </p>
        <p>
          Olet (ja sinun vanhempasi tai laillinen huoltajasi on, jos olet alle
          13-vuotias) vastuussa kaikesta omien rekisteröitymistietojesi avulla
          tapahtuvasta sovelluksen käytöstä sekä puhelimesi tai muun laitteesi
          asianmukaisen tietoturvan järjestämisestä. Sitoudut suojaamaan
          rekisteröitymistietosi väärinkäytöksiltä ja ilmoittamaan meille heti
          mahdollisista väärinkäytöksistä.
        </p>

        <h3>2.2. Ikäraja</h3>
        <p>
          Sinun tulee olla 13-vuotias tai vanhempi, jotta voit itse
          rekisteröityä Ekoekspertti-sovellukseen ja käyttää sitä.
        </p>
        <p>
          Jos olet alle 13-vuotias, voit käyttää sovellusta vain, jos vanhempasi
          tai laillinen huoltajasi on antanut siihen suostumuksensa. Vanhemman
          tai laillisen huoltajan on luettava sovelluksen käyttöehdot kanssasi.
          Jos olet lapsen vanhempi tai laillinen huoltaja ja annat hänen käyttää
          Ekoekspertti-sovellusta, nämä ehdot koskevat sinua, ja olet vastuussa
          lapsesi toiminnasta sovelluksessa.
        </p>
        <p>
          Hyväksymällä nämä käyttöehdot vakuutat toimineesi edellä mainitulla
          tavalla.
        </p>
        <h3>2.3. Ehtojen muuttaminen ja sopimuksen voimassaolo</h3>
        <p>
          Heka voi muuttaa sovelluksen käyttöehtoja ilman ennakkoilmoitusta.
          Voimassaolevat ehdot löydät aina{" "}
          <a
            href="https://www.hekaoy.fi/fi/ekoekspertti"
            target="_blank"
            rel="noopener noreferrer"
          >
            täältä
          </a>
          . Jos muutamme ehtoja olennaisesti, ilmoittamme sinulle muutoksista
          ennen niiden voimaantuloa. Jatkamalla sovelluksen käyttöä hyväksyt
          sovellukseen ja ehtoihin tekemämme muutokset. Jos et hyväksy
          muutettuja ehtoja, sinun on pyydettävä meiltä henkilötietojesi
          poistamista ja lopetettava sovelluksen käyttö.
        </p>
        <p>
          Jos et enää halua käyttää Ekoekspertti-sovellusta, voit milloin
          tahansa irtautua käyttöehtojen mukaisesta sopimuksesta kanssamme
          pyytämällä meiltä henkilötietojesi poistamista ja lopettamalla
          sovelluksen käyttämisen.
        </p>
        <p>
          Hekalla on oikeus ilman erillistä ilmoitusta peruuttaa oikeutesi
          käyttää sovellusta, jos Hekalla on perusteltu syy epäillä, että käytät
          sovellusta tai sen sisältöä käyttöehtojen, lain tai muutoin hyvän
          tavan vastaisesti.
        </p>
        <p>
          Sisällöstä saattaa jäädä jälkiä tai kopioita sovellukseen senkin
          jälkeen, kun sinä olet tai Heka on poistanut sisällön sovelluksesta
        </p>
        <p>
          Jos ilmenee, että jokin käyttöehtojen kohta on mitätön tai pätemätön,
          ehtojen kaikki muut kohdat pysyvät edelleen voimassa.
        </p>

        <h2>3. Sovelluksen käyttö</h2>
        <p>Sinä sitoudut noudattamaan näitä sääntöjä, kun käytät sovellusta:</p>
        <p>
          <ol>
            <li>
              Sinulla on oikeus käyttää sovellusta vain yksityisiin,
              ei-kaupallisiin tarkoituksiin.
            </li>
            <li>Noudatat soveltuvia lakeja, näitä ehtoja ja hyviä tapoja.</li>
            <li>
              Kunnioitat toisten oikeuksia, mukaan luettuna yksityisyyttä ja
              immateriaalioikeuksia.
            </li>
            <li>
              Et toimi haitallisesti itseäsi tai muita kohtaan etkä uhkaile
              tällaisella toiminnalla tai kannusta siihen.
            </li>
            <li>
              Et lähetä laitonta, loukkaavaa, herjaavaa, pornografista,
              häiritsevää, kunniaa loukkaavaa tai muuten sopimatonta sisältöä.
            </li>
            <li>
              Hankit kaikki tarvitsemasi suostumukset, luvat tai oikeudet
              sisällön lailliseen lähettämiseen.
            </li>
            <li>
              Et lähetä sisältöä, joka on roskapostia tai sisältää sovellukselle
              tai sen käyttäjien edulle tai omaisuudelle haitallisia tiedostoja
              tai tekniikoita.
            </li>
            <li>
              Et väärinkäytä tai vahingoita sovellusta tai häiritse sen
              toimintaa.
            </li>
          </ol>
        </p>

        <h2>4. Sisältö sovelluksessa</h2>
        <h3>4.1. Käyttäjän tuottama sisältö</h3>
        <p>
          Voit halutessasi suorittaa sovelluksessa henkilökohtaisia
          haastetehtäviä ja osallistua asuinkohteesi yhteisöhaasteisiin.
          Haastetehtäviä suorittamalla keräät itsellesi ja asuinkohteellesi
          pisteitä, ja näet sovelluksessa oman ja muiden käyttäjien
          pistesijoituksen sekä asuinkohteiden pistesijoitukset. Muut käyttäjät
          eivät näe, mitä henkilökohtaisia haastetehtäviä olet suorittanut, vaan
          ainoastaan keräämäsi pistemäärän pistesijoituksessa. Muut käyttäjät
          saavat tiedon yhteisöhaastetehtävästä, jonka olet suorittanut
          ensimmäisenä asuinkohteessasi.
        </p>
        <p>
          Heka ei tarkista eikä pysty tarkistamaan käyttäjien sovellukseen
          lähettämää sisältöä eikä ole vastuussa kyseisestä sisällöstä. Voimme
          halutessamme tarkistaa ja poistaa sovelluksesta sisältöä.
        </p>
        <p>
          Jos annat meille palautetta, voimme oman harkintamme mukaan ryhtyä
          palautteesi mukaisiin toimiin.
        </p>

        <h3>4.2. Hekan tuottama sisältö</h3>
        <p>
          Sovellus, sen sisältö ja ulkoasu ovat tekijänoikeuslakien ja kaikkien
          muiden soveltuvien lainsäädäntöjen suojaamia. Kaikki oikeudet, kuten
          omistusoikeus, tekijänoikeus ja muut immateriaalioikeudet sovellukseen
          sekä siihen sisältyvään tai liittyvään sisältöön ovat Hekan tai
          lisenssinantajiemme omaisuutta. Pidätämme kaikki oikeudet, joita ei
          nimenomaisesti luovuteta ehdoissa.
        </p>

        <h3>4.3. Muu sisältö</h3>
        <p>
          Sovelluksessa voi olla linkkejä kolmansien osapuolien omistamille tai
          ylläpitämille sivustoille. Näitä sivustoja käytetään kyseisten
          sivustojen sääntöjen mukaisesti.{" "}
        </p>
        <p>
          Heka ei voi vaikuttaa kolmansien osapuolten sivustojen sisältöön eikä
          ole tarkistanut tai hyväksynyt linkillä sovellukseen yhdistettyjä
          kolmansien osapuolten sivustoja. Heka ei siten ole vastuussa niiden
          sisällöstä.
        </p>

        <h2>5. Saatavuus</h2>
        <p>
          Ekoekspertti-sovellus on saatavilla vain Suomessa ja se on
          käytettävissä vain suomen kielellä.
        </p>
        <p>
          Sovelluksen käyttäminen edellyttää verkkoyhteyttä. Sinä vastaat
          sovelluksen käytön edellyttämien mobiililaitteiden, ohjelmistojen ja
          viestintä- sekä tietoliikenneyhteyksien hankkimisesta, kustannuksista
          ja toimivuudesta.
        </p>
        <p>
          Heka voi muuttaa, parantaa ja korjata sovellusta oman harkintansa
          mukaan. Sovellus voi olla poissa käytöstä huoltokatkojen aikana tai
          muina aikoina.
        </p>
        <p>
          Heka voi myös päättää sovelluksen tai sen osan lopettamisesta oman
          harkintansa mukaan. Tällaisessa tapauksessa ilmoitamme asiasta
          etukäteen.
        </p>

        <h2>6. Vastuunrajoitus</h2>
        <p>
          Tarjoamme Ekoekspertti-sovelluksen käyttöösi ”sellaisena kuin se on”.
        </p>
        <p>
          Heka ei vastaa sovelluksen virheellisestä toiminnasta,
          käyttökatkoista, käytön estymisestä taikka sovelluksessa tai sen
          sisällössä olevista virheistä tai puutteellisuuksista. Emme vastaa
          myöskään kolmansista osapuolista johtuvista tiedonsiirtoon liittyvistä
          ongelmista tai virheistä, jotka johtuvat siitä, että sinä käytät
          sovellusta ohjeiden tai määräysten vastaisesti tai jotka ovat muuten
          sinun aiheuttamiasi. Emme vastaa sinulle tai millekään kolmannelle
          osapuolelle sovelluksen tai jonkin sen osan mahdollisesta
          lopettamisesta.
        </p>
        <p>
          Heka ei myönnä sovelluksen saatavuuden, tarkkuuden, luotettavuuden,
          tietojen tai sisällön osalta minkäänlaisia takuita.
        </p>
        <p>
          Hekan vastuu rajoittuu aina korkeintaan pakottavan lainsäädännön
          mukaiseen vastuuseen.
        </p>
        <p>
          Olet velvollinen korvaamaan Hekalle välittömät vahingot, jotka
          aiheutuvat sovelluksen käyttämisestä lain tai näiden ehtojen
          vastaisesti.
        </p>

        <h2>7. Tietosuoja</h2>
        <p>
          Jos käytät Sovellusta, keräämme sinusta Sovelluksessa antamiasi
          tietoja Sovelluksen tarjoamiseksi ja kehittämiseksi sekä sovellus- ja
          käyttäjäviestintää varten. Heka on rekisterinpitäjänä vastuussa siitä,
          että henkilötietojasi käsitellään tietosuojalainsäädännön
          edellyttämällä tavalla. Käsittelemme henkilötietojasi ehtojen ja{" "}
          <a
            href="https://www.hekaoy.fi/asumisen-aikana/asukastoiminta/heta-sovellus-heka-talo-taskussa/#heta-kayttoehdot"
            target="_blank"
            rel="noopener noreferrer"
          >
            tämän tietosuojaselosteen
          </a>{" "}
          mukaisesti.
        </p>

        <h2>8. Erimielisyyksien ratkaiseminen</h2>
        <p>
          Näihin käyttöehtoihin sovelletaan Suomen lakia sen
          lainvalintasäännöksiä lukuun ottamatta.
        </p>
        <p>
          Jos sovellusta tai ehtoja koskevaa erimielisyyttä ei pystytä
          ratkaisemaan sinun ja Hekan välisin neuvotteluin, ratkaistaan
          erimielisyydet ensiasteena Helsingin käräjäoikeudessa.
          Kuluttajakäyttäjänä voit saattaa riidan käsiteltäväksi myös
          kotipaikkasi toimivaltaisen käräjäoikeuden tai
          kuluttajariitalautakunnan (
          <a
            href="http://www.kuluttajariita.fi/"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.kuluttajariita.fi
          </a>
          ) ratkaistavaksi. Ennen asian viemistä kuluttajariitalautakunnan
          käsiteltäväksi sinun on oltava yhteydessä kuluttajaneuvontaan (
          <a
            href="http://www.kuluttajaneuvonta.fi/"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.kuluttajaneuvonta.fi
          </a>
          ).
        </p>

        <h2>9. Yhteystiedot</h2>
        <p>
          Jos sinulla on Ekoekspertti-sovellusta koskevia kysymyksiä, ota
          yhteyttä{" "}
          <a href="mailto:ekoekspertti@hekaoy.fi">ekoekspertti@hekaoy.fi</a>.
        </p>
      </div>
    </div>
  );
}

export default Terms;
