import React from "react";

const PageHeadings = ({ headingTitle }) => {
    const styles={
        fontSize:"24px",
        fontWeight:"700",
        padding:"20px 0 20px"
    }
  return <h1 style={styles}>{headingTitle}</h1>;
};

export default PageHeadings;
