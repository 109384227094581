import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import styles from "./Tabs.module.scss";

const Tabs = ({ tabInfo }) => {
  const { active, color, tabs } = tabInfo;

  return (
    <div className={styles.tabs}>
      {tabs.map((tab, index) => {
        const labelClassNames = classNames(styles.label, {
          [styles.active]: index === active,
          [styles.teal]: color === "teal",
          [styles.yellow]: color === "yellow",
          [styles.orange]: color === "orange",
          [styles.green]: color === "green",
          [styles.blue]: color === "blue",
        });

        return (
          <Link key={index} to={tab.to} className={styles.tab}>
            <span className={labelClassNames}>{tab.label}</span>
            {index + 1 < tabs.length && <div className={styles.divider}></div>}
          </Link>
        );
      })}
    </div>
  );
};

export default Tabs;
