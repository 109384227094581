import React from "react";
import TopNavBar from "../TopNavBar/TopNavBar";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux'

import styles from "./PageSubHeader.module.scss";

const PageSubHeader = ({
  pageSubTitle,
  items,
  currentPage,
  challengeRank,
  rankNumber,
  challengePerformance,
  arrowIcon,
}) => {
  const currentComplexName = useSelector((state) => state.currentComplex.name)

  return (
    <div className={styles.headerContent}>
      {pageSubTitle ? (
        <div className={styles.subTitleContainer}>
          <h1>{pageSubTitle}</h1>
          <h2>{currentComplexName}</h2>
          <div className={styles.challengeRankContainer}>
            <div className={styles.challengeRankWrapper}>
              {/* <div className={styles.rank}>{challengeRank}</div>
              <div className={styles.rankNumber}>{rankNumber}</div> */}
            </div>
            <Link to="/ekoekspertti/achievements/own">
              <div className={styles.challengePerformanceWrapper}>
                <div className={styles.performance}>{challengePerformance}</div>
                {arrowIcon}
              </div>
            </Link>
          </div>
        </div>
      ) : (
        <>
          <TopNavBar items={items} currentPage={currentPage} />
        </>
      )}
    </div>
  );
};

export default PageSubHeader;
