import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import SecurityContext from "../Context/SecurityContext";
import styles from "./ResetPassword.module.scss";
import loginPattern from "../../assets/login-pattern.png";
import { queryParameters } from "../../helpers/helpers";
import { TextInput, InputError, Button } from "../Inputs/Inputs";

export default function ResetPassword(props) {
  const { api } = useContext(SecurityContext);

  const [authParams, setAuthParams] = useState(false);
  const [tokenIsValid, setTokenIsValid] = useState(false);
  const [pswdIsReset, setPswdIsReset] = useState(false);

  useEffect(() => {
    let ignore = false;
    if (!ignore && props.location.search) {
      const auth_params = queryParameters(props.location.search);
      setAuthParams(auth_params);
      setTokenIsValid(auth_params && auth_params.reset_password);
    }
    return () => (ignore = true);
  }, [props.location.search]);

  return (
    <div className={styles.screenContainer}>
      <div className={styles.formContainer}>
        <div className={styles.title}>
          <h1>Heta</h1>
        </div>
        <div>
          {pswdIsReset ? (
            <div className={styles.text}>
              Salasana on vaihdettu. <Link to="/">Kirjaudu sisään</Link>
            </div>
          ) : tokenIsValid ? (
            <ResetPasswordForm
              api={api}
              authParams={authParams}
              setPswdIsReset={setPswdIsReset}
            />
          ) : (
            <div className={styles.text}>
              Linkki on vanhentunut. <Link to="/">Kirjaudu sisään</Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function ResetPasswordForm({ api, authParams, setPswdIsReset }) {
  const [error, setError] = useState("");

  const PasswordSchema = Yup.object().shape({
    new_password: Yup.string()
      .min(6, "Oltava vähintään 6 merkkiä")
      .required("Pakollinen tieto"),
    new_password_again: Yup.string()
      .oneOf([Yup.ref("new_password"), null], "Salasanat eivät täsmää")
      .min(6, "Oltava vähintään 6 merkkiä")
      .required("Pakollinen tieto"),
  });

  const handleSubmit = (values, setSubmitting) => {
    setSubmitting(true);
    setError("");

    api
      .resetPassword(authParams, {
        password: values.new_password,
        password_confirmation: values.new_password_again,
      })
      .then(data => {
        setSubmitting(false);
        if (data && data.success) {
          setPswdIsReset(true);
        } else if (data && data.errors) {
          setError(data.errors.full_messages && data.errors.full_messages[0]);
        }
      })
      .catch(err => {
        setSubmitting(false);
        if (err.errors) {
          setError(err.errors.full_messages && err.errors.full_messages[0]);
        }
      });
  };

  return (
    <Formik
      initialValues={{
        new_password: "",
        new_password_again: "",
      }}
      validationSchema={PasswordSchema}
      onSubmit={(values, { setSubmitting }) =>
        handleSubmit(values, setSubmitting)
      }
    >
      {props => {
        const { isValid, isSubmitting } = props;

        return (
          <Form>
            <div className="margin-1 margin-bottom">
              <TextInput
                formik
                required
                type="password"
                name="new_password"
                label="Uusi salasana"
              />
              <ErrorMessage name="new_password" component={InputError} />
              <TextInput
                formik
                required
                type="password"
                name="new_password_again"
                label="Toista uusi salasana"
              />
              <ErrorMessage name="new_password_again" component={InputError} />
              {error && <InputError>{error}</InputError>}
            </div>

            <div className={styles.submit}>
              <Button blueButton type="submit" disabled={isSubmitting || !isValid}>
                Vaihda salasana
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
