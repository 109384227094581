import React from "react";
import styles from "./Modal.module.scss";

const Modal = ({ children }) => {
  return (
    <div className={styles.modalWrapper}>
      <div className={styles.modalBox}>{children}</div>
    </div>
  );
};

export default Modal;

