import { Preferences as Storage } from "@capacitor/preferences";
import { Device } from "@capacitor/device";

export const apiUrl = path => {
  const apiHost = process.env.REACT_APP_API_HOST;
  if (apiHost && path.slice(0, 6) !== "https:") {
    return `https://${apiHost}${path}`;
  } else {
  return path;
  }
};

async function getDeviceRedirectUrl(path, domain) {
  return `https://${domain}${path}`;
}

export async function redirectUrl(path) {
  const currentEnv = process.env.REACT_APP_ENV;
  const currentApp = process.env.REACT_APP_TYPE;
  if (currentEnv && path.slice(0, 6) !== "https:") {
    if (currentEnv === "staging") {
      if (currentApp === "heta") {
        return await getDeviceRedirectUrl(
          path,
          "ada-test.hekaoy.fi"
        );
      } else {
        return await getDeviceRedirectUrl(
          path,
          "ekoekspertti-staging.netlify.app"
        );
      }
    } else if (currentEnv === "production") {
      if (currentApp === "heta") {
        return await getDeviceRedirectUrl(
          path,
          "heta.hekaoy.fi"
        );
      } else {
        return await getDeviceRedirectUrl(
          path,
          "ekoekspertti-production.netlify.app"
        );
      }
    } else {
      return window.location.origin + path;
    }
  } else {
    return path;
  }
}

export const getLocalStorage = key => {
  let value = null;
  const localStorageVal = localStorage.getItem(key);
  if (localStorageVal) {
    value =
      key === "filter_params" ? JSON.parse(localStorageVal) : localStorageVal;
  }
  return value;
};

function getStorageToken() {
  return Storage.get({ key: "tokenData" }).then(ret => {
    return ret && ret.value && JSON.parse(ret.value);
  });
}

async function setStorageToken(name, val) {
  await Storage.set({
    key: name,
    value: JSON.stringify(val),
  });
}

async function removeStorageToken() {
  await Storage.remove({ key: "tokenData" });
}

export { getStorageToken, setStorageToken, removeStorageToken };

/*export const getTokenData = () => {
  const data = getLocalStorage("tokenData");
  return data && JSON.parse(data);
};*/

export function queryParameters(search) {
  if (!search) {
    return {};
  }

  return search
    .slice(1)
    .split("&")
    .reduce((acc, segment) => {
      const pair = segment.split("=");
      acc[pair[0]] = pair[1];
      return acc;
    }, {});
}

export function getYears() {
  const current = new Date();
  const currentYear = current.getFullYear();
  let i = 2019;
  const arr = [i];
  while (i < currentYear) {
    arr.push(i + 1);
    i++;
  }
  return arr.reverse().map(y => y.toString());
}