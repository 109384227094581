import styles from "./FrontPageEvent";

export function createDescriptionContent(description, richTextDescription) {
    if (description) {
        return (
            <div className={styles.descriptionContent}>
                <p>{description}</p>
            </div>
        )
    }

    return (
        <div
            className={styles.descriptionContent}
            dangerouslySetInnerHTML={{ __html: richTextDescription }}
        />
    )
}

export function createDateString(dateISO) {
    const date = new Date(dateISO)

    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
}