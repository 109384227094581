import React, { useContext, useEffect, useState } from "react";
import { TabPage, TransitionContainer } from "../PageContainer/PageContainer";
import HistorySelector from "../HistorySelector/HistorySelector";
import AchievementDisplay from "../AchievementDisplay/AchievementDisplay";
import Ranking from "../Ranking/Ranking";
import { getYears } from "../../helpers/helpers";
import SecurityContext from "../Context/SecurityContext";

export default function CommunityAchievements(props) {
  const {
    communityAchievementsHistory,
    setCommunityAchievementsHistory,
  } = props;
  const { api } = useContext(SecurityContext);

  const [complexId, setComplexId] = useState(null);
  const [complexLoading, setComplexLoading] = useState(true);
  const [ranking, setRanking] = useState([]);
  const [ranking10, setRanking10] = useState([]);
  const [achievements, setAchievements] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingTop10, setLoadingTop10] = useState(false);

  const years = getYears();
  const year =
    communityAchievementsHistory > 0
      ? years[communityAchievementsHistory]
      : null;

  useEffect(() => {
    let ignore = false;
    if (!ignore && api) {
      setComplexLoading(true);
      api
        .getCurrentComplex()
        .then(data => {
          if (data && data.id) {
            setComplexId(data.id);
          }
          setComplexLoading(false);
        })
        .catch(err => {
          setComplexLoading(false);
          console.log(err);
        });
    }
    return () => (ignore = true);
  }, [api]);

  useEffect(() => {
    let ignore = false;
    if (!ignore && api) {
      api
        .getComplexStatistics(year)
        .then(data => {
          if (data) {
            setAchievements(data);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
    return () => (ignore = true);
  }, [api, year]);

  useEffect(() => {
    let ignore = false;
    if (!ignore && api && !complexLoading) {
      setLoading(true);
      api
        .getUserCommunal(year)
        .then(data => {
          if (data) {
            transformRanksData(data, complexId);
            setRanking(data);
          }
          setLoading(false);
        })
        .catch(err => {
          console.log(err);
          setLoading(false);
        });
    }
    return () => (ignore = true);
  }, [api, year, complexId, complexLoading]);

  useEffect(() => {
    let ignore = false;
    if (!ignore && api && !complexLoading) {
      setLoadingTop10(true);
      api
        .getUserCommunalTop10(year)
        .then(data => {
          if (data) {
            transformRanksData(data, complexId);
            setRanking10(data);
          }
          setLoadingTop10(false);
        })
        .catch(err => {
          console.log(err);
          setLoadingTop10(false);
        });
    }
    return () => (ignore = true);
  }, [api, year, complexId, complexLoading]);

  return (
    <TabPage>
      <HistorySelector
        history={communityAchievementsHistory}
        setHistory={setCommunityAchievementsHistory}
        historyItems={years}
        context="Suoritukset ajalta"
      />
      <AchievementDisplay type="communal" achievements={achievements} />
      <Ranking
        title="Yhteisön sijoitus"
        type="communal"
        ranking={ranking}
        loading={complexLoading || loading}
      />
      <Ranking
        title="Top 10"
        type="communal"
        ranking={ranking10}
        loading={complexLoading || loadingTop10}
      />
    </TabPage>
  );
}

function transformRanksData(data, complexId) {
  data.forEach(i => {
    if (i.scorer_id === complexId) {
      i.self = true;
    }
  });
  return data;
}
