import React, { useContext, useEffect, useState } from "react";

import PageContainer from "../components/PageContainer/PageContainer";

import Feed from "../components/Feed/Feed";
import SecurityContext from "../components/Context/SecurityContext";
import AnnouncementTags from "../components/AnnouncementTags/AnnouncementTags";
import PageAlignment from "../components/common/PageAlignment/PageAlignment";
import PageSubHeader from "../components/PageSubHeader/PageSubHeader";
import { Button } from "../components/Inputs/Inputs";
// import SearchBar from "../components/SearchBar/SearchBar";

const Home = ({ items, currentPage }) => {
  const { api } = useContext(SecurityContext);
  const [feed, setFeed] = useState({
    error: null,
    data: {
      current_building_complex: {},
      feed_items: [],
    },
  });
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [error, setError] = useState("");

  const [filterTags, setFilterTags] = useState({
    Heka: false,
    Ekoekspertti: false,
    userComplex: false,
    important: false,
  })

  const handleCheck = value => {
    const filters = {
      ...filterTags,
    };
    filters[value] = !filterTags[value];

    setFilterTags({
      ...filters,
    });
  };

  const openURL = url => {
    window.open(url);
  };

  useEffect(() => {
    let ignore = false;
    if (!ignore && api) {
      setLoading(true);
      api
        .getFeed(filterTags)
        .then(response => {
          if (response && response.data) {
            setFeed({ data: response.data, error: null });
          }
          setLoading(false);
        })
        .catch(err => {
          console.log(err);
          setError("Tietojen haku epäonnistui");
          /** There is a bug when user logs in, it throws a 401 unauthorized error for a split second before the  feed items are loaded  */
          // setFeed({ data: null, error: "Tietojen haku epäonnistui" });
          setLoading(false);
        });
    }
    return () => (ignore = true);
  }, [api, filterTags]);

  return (
    <PageContainer>
      <PageSubHeader
        openURL={openURL}
        items={items}
        currentPage={currentPage}
      />
      <PageAlignment>
        {/* <SearchBar
          searchDescription="Hae tiedotteita"
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        /> */}

        <div>
          <Button
            redButton
            onClick={() =>
              openURL(
                "https://portaali.tampuuri.fi/helsinginkaupunginasunnotoy/vikailmoitus"
              )
            }
          >
            Jätä vikailmoitus
          </Button>
        </div>

        <AnnouncementTags
          handleCheck={handleCheck}
          filterTags={filterTags}
        />
        
        <Feed
          error={error}
          feed={feed}
          loading={loading}
          searchQuery={searchQuery}
          filterTags={filterTags}
        />
      </PageAlignment>
    </PageContainer>
  );
};

export default Home;
