import React, { useState, useRef, useEffect } from "react"
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import { Collapse } from "react-collapse";

import {
    postContainsSearchQuery
} from './utils'

import styles from "./Post.module.scss";

export default function Post({ post, searchQuery }) {

    const chevronIcon = useRef()

    const [dropDownOpen, setDropDownOpen] = useState(false)

    const openDropDown = () => {
        if (dropDownOpen) {
            chevronIcon.current.style.transform = 'rotate(0)'
            setDropDownOpen(false)
        } else {
            chevronIcon.current.style.transform = 'rotate(180deg)'
            setDropDownOpen(true)
        }
    }

    useEffect(() => {
        const contains = postContainsSearchQuery(post, searchQuery)

        if (contains && !dropDownOpen) {
            openDropDown()
        }

        else if (!contains && dropDownOpen) {
            openDropDown()
        }
    }, [searchQuery])

    return (
        <div>
            <div onClick={openDropDown} className={styles.titleContainer}>
                <p>{post.title}</p>
                <div className={styles.chevronIcon} ref={chevronIcon}>
                    <ChevronDownIcon />
                </div>
            </div>

            <Collapse isOpened={dropDownOpen}>
                <div className={styles.content}>
                    <div
                        dangerouslySetInnerHTML={{ __html: post.content }}
                    />
                </div>
            </Collapse>
        </div>
    )
}