import React, { useContext, useState, useRef } from "react";
import { Button } from "../Inputs/Inputs";
import styles from "./BuildingPoll.module.scss";
import inputStyles from "../Inputs/Inputs.module.scss";
import SecurityContext from "../Context/SecurityContext";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import { Collapse } from "react-collapse";
import { Formik, Form, Field } from "formik";
import { FiCheck } from "react-icons/fi";
import {
  findFieldValue
} from './utils'

import "react-edit-text/dist/index.css";
import { useEffect } from "react";

const BuildingPoll = ({ proposalData }) => {
  const { data } = proposalData;

  return (
    <div>
      {data?.polls.map(poll => {

        return (
          <div key={poll.id}>
            <PollContent
              pollData={poll}
            />
          </div>
        );
      })}
    </div>
  );
};

const PollContent = (data) => {
  const [pollOpen, setPollOpen] = useState(!data.pollData.dynamic_form_submission)

  const pollContent = useRef();
  const chevronIcon = useRef();

  const openPoll = () => {
    if (!pollOpen) {
      setPollOpen(true)
      chevronIcon.current.style.transform = 'rotate(180deg)'
    } else {
      setPollOpen(false)
      chevronIcon.current.style.transform = 'rotate(0deg)'
    }
  }

  const chevronStyle = {
    transform: !data.pollData.dynamic_form_submission ? 'rotate(180deg)' : 'rotate(0deg)'
  }

  return (
    <div className={styles.pollContainer}>
      <div onClick={openPoll} className={styles.pollHeader}>
        <div className={styles.pollHeaderContent}>
          <p>Asukastoimikunta kysyy</p>
          <p className={styles.pollHeaderDate}>Päättyy {data.pollData.end_date}</p>
        </div>
      </div>
      <div className={styles.pollWrapper}>
        <div onClick={openPoll} className={styles.pollTitleWrapper}>
          <h1 className={styles.pollTitle}>{data.pollData.title}</h1>
          <div style={chevronStyle} className={styles.chevronIcon} ref={chevronIcon}>
            <ChevronDownIcon />
          </div>
        </div>

        <Collapse isOpened={pollOpen}>
          <div className={styles.pollContent} ref={pollContent}>
            <PollForm poll={data.pollData} />
          </div>
        </Collapse>
      </div>
    </div>
  )
}

export const PollForm = ({ poll }) => {
  const formRef = useRef();
  const { api } = useContext(SecurityContext);
  const [dynamicFormId, setDynamicFormId] = useState(poll?.dynamic_form_submission?.id || undefined);
  const [thePoll, setThePoll] = useState(poll);
  const [pollData, setPollData] = useState(poll?.dynamic_form_submission?.form_field_values || []);
  const [isEditing, setIsEditing] = useState((!poll.dynamic_form_submission?.form_field_values?.length > 0));

  const [radioValue, setRadioValue] = useState("");

  const handlePollSubmission = () => {
    const payload = {
      dynamic_form_submission: {
        dynamic_form_id: poll.form.id,
        poll_id: poll.id,
        form_field_values_attributes: pollData,
      },
    };

    if (dynamicFormId === undefined) {
      api
        .submitPollForm(payload)
        .then(response => {
          if (response) {
            setPollData(response.dynamic_form_submission.form_field_values);
            setThePoll(response);
            setDynamicFormId(response.dynamic_form_submission.id);
            setIsEditing(false);
          }
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      api
        .updatePollForm(dynamicFormId, payload)
        .then(response => {
          if (response) {
            setPollData(response.dynamic_form_submission.form_field_values);
            setThePoll(response);
            setDynamicFormId(response.dynamic_form_submission.id);
            setIsEditing(false);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const handleOnChange = event => {
    setRadioValue(event.target.value);
  };

  const handleValueChange = (value, index, id) => {
    var newPollData = pollData
    if (newPollData[index]) {
      newPollData[index].form_field_id = id
      newPollData[index].value = value
    } else {
      newPollData[index] = { form_field_id: id, value: value }
    }
    setPollData(newPollData);
  }

  return (
    <div className={styles.pollForm}>
      {isEditing ? (
        <Formik
          innerRef={formRef}
        >
          {({ values }) => {
            return (
              <Form onChange={handleOnChange}>
                {thePoll?.form.form_fields.map((pollForm, index) => (
                  <FormFields
                    pollForm={pollForm}
                    key={thePoll + index}
                    poll={thePoll}
                    handlePollSubmission={handlePollSubmission}
                    handleValueChange={handleValueChange}
                    pollData={pollData}
                    radioValue={radioValue}
                    value={findFieldValue(pollForm, thePoll)}
                    index={index}
                  />
                ))}
                <Button
                  whiteButton
                  type="button"
                  onClick={() => handlePollSubmission()}
                >
                  Jätä vastaus
                </Button>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <div>
          <div>
            <div className={styles.answersContainer}>Vastauksesi:</div>
            {thePoll?.form.form_fields.map((datum, index) => (
              thePoll.form.form_fields[index].input_type === "radio"
                ? (
                  <div className={styles.pollBox} key={datum.id}>
                    <strong>{thePoll.form.form_fields[index].label}</strong>
                    {thePoll.form.form_fields[index].options.map((option, index) => (
                      (
                        <div key={option.value} className={[styles.customRadio, option.label === findFieldValue(datum, thePoll) ? styles.myAnswer : styles.notMyAnswer].join(' ')}>
                          <div className={styles.pollBar} style={{ width: `${option.percentage}%` }}>
                          </div>
                          <div className={styles.filledLabel}>
                            {option.label}
                          </div>
                          <div className={styles.pollPercentage}>
                            {option.percentage}%
                          </div>
                        </div>
                      )
                    ))}
                  </div>
                )
                : (
                  <div className={styles.pollBox} key={datum.id}>
                    <strong>{thePoll.form.form_fields[index].label}</strong>
                    <p>{findFieldValue(datum, thePoll)}</p>
                  </div>
                )
            ))}
          </div>
          <Button
            whiteButton
            type="button"
            onClick={() => setIsEditing(true)}
          >
            Muokkaa vastaustasi
          </Button>
        </div>
      )}
    </div>
  );
};

export const FormFields = ({
  pollForm,
  submittedPoll,
  handlePollSubmission,
  initialFieldvalues,
  pollData,
  poll,
  radioValue,
  textValue,
  setSumbittedPoll,
  isPollSubmitted,
  handleValueChange,
  value,
  index,
}) => {
  const { id, input_type, label, placeholder, required, options } = pollForm;
  const [fieldValue, setFieldValue] = useState(value);

  const handleFieldValueChange = (value, id) => {
    setFieldValue(value)
    handleValueChange(value, index, id)
  }

  return (
    <div className={styles.formField}>
      {id && (
        <>
          {input_type === "radio" && options.length && options && (
            <div className={styles.inputContainer}>
              <p>{label}</p>
              {options.map((option, index) => {
                const getOptionValues = options?.filter(
                  formoption => formoption.value === option.value
                );

                return <label
                  className={styles.customRadio}
                  key={id + "-radio-" + index}
                >
                  <Field
                    type="radio"
                    id={id}
                    name={id}
                    value={option.label}
                    onChange={e => handleFieldValueChange(e.target.value, id)}
                  />
                  <div
                    className={styles.customLabel}
                  >
                    <p className={styles.filledLabel}>
                      {option.label}
                      {getOptionValues &&
                        getOptionValues[0]?.label === radioValue ? (
                        <FiCheck className={styles.checkMarkIcon} />
                      ) : null}
                    </p>
                  </div>
                </label>

              })}
            </div>
          )}
          {input_type === "textarea" && (
            <div>
              <p>{label}</p>
              <textarea
                name={id}
                placeholder={placeholder}
                required={required}
                className={inputStyles.textAreaBoxWhite}
                value={fieldValue}
                onChange={e => handleFieldValueChange(e.target.value, id)}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BuildingPoll;
